.logos {
	overflow: hidden;

	&--land {
		margin-top: ac(80px, 40px);
	}
}

.logos-slider {
	max-width: ac(1136px, 172px, 375);

	margin: 0 auto;
	padding: 8px 0;

	.swiper-slide {
		height: auto;
	}

	@mixin media 551 {
		max-width: 172px;
	}
}

.logos-slider__card {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 100%;

	padding: ac(29px, 11px) ac(20px, 11px);
	border: 1px solid rgba(70, 158, 222, 0.3);
	border-radius: 8px;

	img {
		max-height: ac(98px, 76px);
		height: auto;
		width: auto;
		object-fit: unset;
	}
}

.logos-slider__logo {
	max-height: ac(98px, 76px);
	height: auto;
	width: auto;
	object-fit: unset;
}
