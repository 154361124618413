.hero {
	position: relative;
	z-index: 2;

	padding-top: ac(182px, 178px);
	padding-bottom: ac(87px, 24px);

	background: linear-gradient(111deg, #469ede 0%, #4291cc 100%);

	.cont {
		position: relative;
		z-index: 1;
	}

	&__title {
		color: var(--cl-white);
	}

	&__cloud {
		position: absolute;

		&.mob {
			display: none;
		}

		&.top {
			top: var(--header-height);
			width: 100%;
			height: auto;
		}

		&.bottom {
			bottom: 0;
			right: 0;
		}
	}

	&--profile,
	&--job,
	&--article {
		overflow: hidden;
	}

	&--emp-search,
	&--job-search {
		.hero__form-btn {
			min-width: 200px;
			height: ac(54px, 47px);
		}
	}

	&--home,
	&--emp-search,
	&--job-search {
		.hero__cloud {
			&.bottom {
				height: auto;
				width: auto;
			}
		}

		.hero__title {
			margin: 0 auto ac(48px, 30px);
		}
	}

	&--emp-search,
	&--job-search {
		background: var(--cl-blue);

		.hero__cloud {
			&.bottom {
				left: 0;
			}
		}

		.hero__title {
			margin-left: 0;
		}

		.hero__decor {
			width: 461px;
			height: 424px;

			transform: translate(
				calc(-50% + ac(600px, 100px)),
				calc(-50% + 55px)
			);
		}
	}

	&--job-search {
		.hero__form-keyword {
			width: 34%;
		}

		.hero__form-btn {
			padding-right: 15px;
		}
	}

	&--emp {
		margin-top: ac(104px, 70px);
		padding-top: ac(181px, 80px);
		padding-bottom: ac(202px, 30px);
		background: unset;

		.hero__cloud.top {
			top: 0;
			min-width: 700px;
			left: 50%;
			transform: translateX(-50%);
		}

		.hero__title {
			margin-left: 0;
			margin-bottom: 23px;
			color: var(--cl-black);
			line-height: ac(72px, 45px);
		}

		.hero__subtitle {
			color: var(--cl-dark-gray);
			font-size: ac(24px, 18px);
			line-height: ac(32px, 24px);
			max-width: ac(620px, 300px, 658);
		}
	}

	&--land {
		margin-top: ac(104px, 70px);
		padding-top: ac(181px, 80px);
		padding-bottom: ac(202px, 30px);
		background: unset;

		.hero__title {
			margin-left: 0;
			margin-bottom: 23px;
			color: var(--cl-black);
			line-height: ac(72px, 45px);
		}

		.hero__subtitle {
			color: var(--cl-dark-gray);
			font-size: ac(24px, 18px);
			line-height: ac(32px, 24px);
			max-width: ac(620px, 300px, 658);

			p, li {
				font-size: ac(24px, 18px);
				line-height: ac(32px, 24px);
			}
		}
	}

	&--article {
		margin-top: ac(104px, 70px);
		padding: ac(120px, 40px) 0;
		background: #085d9b;

		.hero__title {
			margin-top: ac(23px, 10px);
			font-family: var(--font-main);
			font-size: ac(48px, 40px);
			line-height: ac(56px, 45px);
			font-weight: 600;
		}

		.hero__suptitle {
			color: var(--cl-white);
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;

			span {
				opacity: 0.7;
				margin-right: 8px;
				&::before {
					content: '';
					transform: translateY(-1px);

					width: 16px;
					height: 17px;
					margin: 0 8px 0 2px;
					flex-shrink: 0;
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none'%3E%3Cpath fill='%23fff' d='M14.25 1.5h-1.875V.875a.625.625 0 1 0-1.25 0V1.5h-6.25V.875a.625.625 0 0 0-1.25 0V1.5H1.75A1.25 1.25 0 0 0 .5 2.75v12.5a1.25 1.25 0 0 0 1.25 1.25h12.5a1.25 1.25 0 0 0 1.25-1.25V2.75a1.25 1.25 0 0 0-1.25-1.25Zm-2.995 7.942-3.75 3.75a.626.626 0 0 1-.885 0l-1.875-1.875a.625.625 0 0 1 .885-.884l1.433 1.433 3.307-3.308a.626.626 0 0 1 .885.884ZM1.75 5.25v-2.5h1.875v.625a.625.625 0 0 0 1.25 0V2.75h6.25v.625a.625.625 0 1 0 1.25 0V2.75h1.875v2.5H1.75Z'/%3E%3C/svg%3E")
						center / contain no-repeat;
				}
			}
		}

		.hero__tag-box {
			margin-top: 31px;
		}

		.hero__tag {
			padding: 8px;
			border-radius: 0 0 6px 6px;
			background-color: var(--cl-blue-gray);
			font-size: 14px;
			line-height: 15px;
			font-weight: 500;
		}
	}

	&--job {
		padding-top: ac(190px, 120px);
		padding-bottom: ac(160px, 24px);
		background: #085d9b;

		.btn.light {
			&:hover {
				background-color: var(--cl-blue);
			}
		}

		.hero__title {
			margin-bottom: ac(57px, 20px);
			font-size: ac(56px, 40px);
			line-height: ac(64px, 45px);
		}

		.hero__tag {
			padding: 8px;
			border-radius: 6px;
			font-size: 14px;
			line-height: 15px;
			font-weight: 500;

			color: #fff;
			background-color: rgba(255, 255, 255, 0.1);
		}

		.hero__date,
		.hero__info {
			color: #fff;
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;

			span {
				opacity: 0.7;

				&::before {
					content: '';
					margin-right: 6px;
					flex-shrink: 0;
					width: 20px;
					height: 20px;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}
			}

			a {
				font-size: inherit;
				line-height: inherit;
				text-decoration: underline;
				transition: 0.3s ease;

				&:hover {
					color: var(--cl-blue);
				}
			}
		}

		.hero__date {
			margin-bottom: 20px;

			span {
				margin-right: 9px;
			}

			span::before {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M10 1.875A8.125 8.125 0 1 0 18.125 10 8.133 8.133 0 0 0 10 1.875Zm4.375 8.75H10A.625.625 0 0 1 9.375 10V5.625a.625.625 0 0 1 1.25 0v3.75h3.75a.625.625 0 1 1 0 1.25Z' /%3E%3C/svg%3E");
			}
		}

		.hero__info-box {
			border: 1px solid var(--cl-white);
			padding: ac(37px, 15px) ac(39px, 15px);
			border-radius: 16px;
		}

		.hero__info {
			gap: 6px;

			&:not(:first-child) {
				margin-top: ac(21px, 10px);
			}

			&--country {
				span:before {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M17.5 3.75v9.375a.625.625 0 0 1-.216.469C16.091 14.627 14.95 15 13.861 15c-1.477 0-2.857-.683-4.14-1.316-2.075-1.028-3.878-1.918-5.971-.264v3.455a.625.625 0 0 1-1.25 0V3.75a.625.625 0 0 1 .216-.469C5.53.845 8.053 2.093 10.278 3.194c2.144 1.06 4.002 1.978 6.188.087a.625.625 0 0 1 1.034.469Z'/%3E%3C/svg%3E");
				}
			}
			&--location {
				span:before {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M10 1.25a6.883 6.883 0 0 0-6.875 6.875c0 5.883 6.25 10.326 6.516 10.512a.625.625 0 0 0 .718 0c.266-.186 6.516-4.63 6.516-10.512A6.883 6.883 0 0 0 10 1.25Zm0 4.375a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z'/%3E%3C/svg%3E");
				}
			}
			&--job {
				span:before {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M12 8.875a.625.625 0 0 1-.625.625h-2.5a.625.625 0 0 1 0-1.25h2.5a.625.625 0 0 1 .625.625Zm6.25-3.125v10A1.25 1.25 0 0 1 17 17H3.25A1.25 1.25 0 0 1 2 15.75v-10A1.25 1.25 0 0 1 3.25 4.5h3.125v-.625A1.875 1.875 0 0 1 8.25 2H12a1.875 1.875 0 0 1 1.875 1.875V4.5H17a1.25 1.25 0 0 1 1.25 1.25ZM7.625 4.5h5v-.625A.625.625 0 0 0 12 3.25H8.25a.625.625 0 0 0-.625.625V4.5ZM17 9V5.75H3.25V9A14.376 14.376 0 0 0 17 9Z' /%3E%3C/svg%3E");
				}
			}
			&--sectors {
				span:before {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M18.728 16.25H17.5V7.5a1.25 1.25 0 0 0-1.25-1.25h-3.437a.313.313 0 0 0-.313.313v9.687h-1.25V2.532a1.284 1.284 0 0 0-.48-1.016 1.25 1.25 0 0 0-1.463-.054l-6.25 4.163a1.25 1.25 0 0 0-.557 1.042v9.583H1.271a.639.639 0 0 0-.647.584.625.625 0 0 0 .625.666h17.5a.625.625 0 0 0 .625-.666.639.639 0 0 0-.646-.584ZM5.937 14.375A.624.624 0 0 1 5.27 15a.639.639 0 0 1-.584-.647v-1.207a.64.64 0 0 1 .584-.646.626.626 0 0 1 .666.625v1.25Zm0-4.375a.624.624 0 0 1-.666.625.64.64 0 0 1-.584-.647V8.771a.64.64 0 0 1 .584-.646.625.625 0 0 1 .666.625V10Zm3.125 4.375a.624.624 0 0 1-.666.625.64.64 0 0 1-.584-.645v-1.209a.64.64 0 0 1 .584-.645.626.626 0 0 1 .666.625v1.249Zm0-4.375a.624.624 0 0 1-.666.625.64.64 0 0 1-.584-.645V8.77a.64.64 0 0 1 .584-.645.625.625 0 0 1 .666.625V10Z' /%3E%3C/svg%3E");
				}
			}
			&--date {
				span:before {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M15.625 5.91V3.124a1.25 1.25 0 0 0-1.25-1.25h-8.75a1.25 1.25 0 0 0-1.25 1.25v2.813a1.255 1.255 0 0 0 .5 1L8.959 10l-4.084 3.063a1.255 1.255 0 0 0-.5 1v2.812a1.25 1.25 0 0 0 1.25 1.25h8.75a1.25 1.25 0 0 0 1.25-1.25v-2.784a1.255 1.255 0 0 0-.496-.997L11.037 10l4.092-3.093a1.257 1.257 0 0 0 .496-.998Zm-1.7 7.84H6.04L10 10.781l3.924 2.969Zm.45-7.84L10 9.218 5.625 5.938V3.124h8.75v2.784Z' /%3E%3C/svg%3E");
				}
			}
		}
	}

	&--profile {
		padding-top: ac(190px, 120px);
		padding-bottom: ac(160px, 24px);
		background: #085d9b;

		.btn.light {
			&:hover {
				background-color: var(--cl-blue);
			}
		}
		.hero__title {
			margin-bottom: ac(24px, 15px);
		}

		.hero__tag-box {
			margin-bottom: 39px;
		}

		.hero__tag {
			padding: 8px;
			border-radius: 6px;
			font-size: 14px;
			line-height: 15px;
			font-weight: 500;

			color: #fff;
			background-color: rgba(255, 255, 255, 0.1);
		}

		.hero__info {
			color: #fff;
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;

			&:not(:nth-child(1 of .hero__info)) {
				margin-top: 12px;
			}

			span {
				opacity: 0.7;
				margin-right: 9px;

				&::before {
					content: '';
					margin-right: 6px;
					flex-shrink: 0;
					width: 20px;
					height: 20px;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M16.25 3.125H3.75A1.875 1.875 0 0 0 1.875 5v8.75a1.875 1.875 0 0 0 1.875 1.875h5.625v1.25H7.5a.625.625 0 1 0 0 1.25h5a.624.624 0 1 0 0-1.25h-1.875v-1.25h5.625a1.875 1.875 0 0 0 1.875-1.875V5a1.875 1.875 0 0 0-1.875-1.875Zm0 11.25H3.75a.625.625 0 0 1-.625-.625V12.5h13.75v1.25a.624.624 0 0 1-.625.625Z' /%3E%3C/svg%3E");
				}
			}

			a {
				font-size: inherit;
				line-height: inherit;
				text-decoration: underline;
				transition: 0.3s ease;

				&:hover {
					color: var(--cl-blue);
				}
			}

			&--country {
				span:before {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M17.5 3.75v9.375a.625.625 0 0 1-.216.469C16.091 14.627 14.95 15 13.861 15c-1.477 0-2.857-.683-4.14-1.316-2.075-1.028-3.878-1.918-5.971-.264v3.455a.625.625 0 0 1-1.25 0V3.75a.625.625 0 0 1 .216-.469C5.53.845 8.053 2.093 10.278 3.194c2.144 1.06 4.002 1.978 6.188.087a.625.625 0 0 1 1.034.469Z' opacity='.8'/%3E%3C/svg%3E");
				}
			}
			&--location {
				span:before {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23fff' d='M10 1.25a6.883 6.883 0 0 0-6.875 6.875c0 5.883 6.25 10.326 6.516 10.512a.625.625 0 0 0 .718 0c.266-.186 6.516-4.63 6.516-10.512A6.883 6.883 0 0 0 10 1.25Zm0 4.375a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z' opacity='.7'/%3E%3C/svg%3E");
				}
			}
		}

		.hero__btn-box {
			margin-top: ac(37px, 20px);
		}

		.hero__img-box {
			position: relative;
			margin-top: ac(24px, 15px);
			margin-right: ac(24px, 15px);

			border-radius: 16px;
			width: 100%;
			height: ac(336px, 200px);
			padding: ac(66px, 30px);
			background-color: var(--cl-white);
			border: 1px solid var(--cl-blue);

			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: ac(-24px, -15px);
				right: ac(-24px, -15px);
				border-radius: 16px 40px 16px 16px;
				background-color: #4291cc;
				border: 1px solid var(--cl-blue);
				z-index: -1;
			}
		}
	}

	&--insights {
		padding-top: ac(168px, 178px);
		padding-bottom: ac(78px, 24px);
		background: #085d9b;

		.hero__slider {
			margin-top: ac(36px, 0px);
		}

		.hero__card {
			background-color: #f7f7f7;
			overflow: hidden;
			border-radius: 20px;
		}

		.hero__col {
			padding: ac(40px, 15px) ac(30px, 5px) ac(40px, 15px) ac(40px, 15px);
		}

		.hero__date {
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;

			span {
				display: inline-block;
				margin-right: 4px;
				color: #757575;
			}
		}

		.hero__name {
			margin-top: 13px;
			color: var(--cl-dark-blue);
			font-weight: 600;
			font-size: ac(32px, 22px);
			line-height: ac(34px, 26px);
		}

		.hero__scroll {
			margin-bottom: ac(40px, 20px);
			padding-right: 10px;
			margin-top: ac(19px, 10px);
			max-height: calc(ac(28px, 24px) * 10);

			.simplebar-track.simplebar-vertical {
				width: 4px;
				background-color: rgba(70, 158, 222, 0.2);
			}

			.simplebar-scrollbar::before {
				opacity: 1;
				inset: 0px;
				background-color: var(--cl-blue);
			}
		}

		.hero__descr {
			font-size: ac(18px, 16px);
			line-height: ac(28px, 24px);
			@mixin max-line-length 6;
			overflow: hidden;
			max-height: ac(225px, 193px);
		}

		.btn {
			padding-right: ac(15px, 10px);
		}

		.hero__tag {
			padding: 5px 7px;
			background-color: #c6ddf1;
			border: 1px solid #fff;
			border-top: 0;
			border-radius: 0 0 6px 6px;
			font-weight: 500;
			font-size: 14px;
		}

		.btn-icon-only {
			background: transparent;
			border-color: #fff;

			i {
				color: #fff;
			}

			&:hover {
				background: rgba(70, 158, 222, 0.5);
				border-color: rgb(255, 255, 255, 0.7);

				i {
					opacity: 0.7;
				}
			}
		}
	}

	&-about {
		background: var(--cl-light-gray);
		padding-top: ac(152px, 136px);
		padding-bottom: 0;
		overflow: hidden;
		@media (max-width: 795px) {
			/* padding-bottom: ac(78px, 48px, 400, 795); */
		}
		.hero {
			&__title {
				color: var(--cl-black);
				line-height: 1.1;
				margin-bottom: ac(22px, 20px);
			}
			&__subtitle {
				font-size: ac(24px, 20px);
				font-weight: 400;
				line-height: 1.15;
				margin-bottom: ac(40px, 24px);
			}
		}
		&__wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media (max-width: 795px) {
				flex-direction: column;
			}
		}
		&__text {
			max-width: ac(534px, 380px, 992);
			margin-top: ac(-62px, -36px);
		}
		&__image { 
			z-index: 4;
			max-width: ac(530px, 340px, 992);
			position: relative;
			margin-right: 26px;
			@media (max-width: 795px) {
				margin-right: 0;
				margin-top: ac(48px, 32px, 360, 795);
			}
			img {
				width: 100%;
			}
			&-wrap {
				/* position: absolute;
				right: 0;
				top: ac(-110px, -45px, 992);
				max-width: ac(1000px, 500px, 992, 2000); */
			}
			&-decor {
				position: absolute;
				top: calc(50% + 53px);
				transform: translateY(-50%);
				right: ac(-10px, -273px, 1440, 2000);
				z-index: 0;
				@media (max-width: 1440px) {
					right: ac(-273px, -680px, 795, 1440);
				}
				@media (max-width: 795px) {
					bottom: ac(-550px, -180px, 325, 795);
					height: auto;
					right: 0;
					top: auto;
					transform: rotate(90deg) translateY(0);
					width: 100%;
				}
				img {
					width: 100%;
				}
			}
		}
	}

	&__form {
		padding: ac(37px, 20px) ac(48px, 20px) ac(48px, 20px);
		border-radius: 20px;

		background: var(--cl-white);
	}

	&__form-flex {
		display: flex;
		align-items: end;
		gap: ac(8px, 5px);
	}

	&__form-title {
		margin-bottom: ac(22px, 17px);

		font-family: var(--font-title);
		font-size: ac(35px, 26px);
		font-weight: 800;
	}

	&__form-keyword {
		width: 37%;
	}
	&__form-sectors {
		width: 27.7%;
	}
	&__form-location {
		width: 21.75%;
	}

	&__form-btn {
		width: 13.42%;

		margin-left: ac(8px, 0px, 1280);

		padding-right: 16px;

		.btn__icon {
			width: 20px;
		}
	}

	@mixin md {
		&__title {
			max-width: 850px;
		}

		&--emp {
			.hero__title {
				letter-spacing: -0.45px;
			}
		}

		&--insights {
			.hero__img {
				max-width: ac(634px, 300px);
			}
		}

		&--article {
			.hero__wrapper {
				max-width: ac(512px, 350px);
			}

			.hero__bg {
				transform: translateX(calc(-50% + ac(313px, 250px)));
				width: ac(814px, 500px);
			}
		}

		&--profile {
			.hero__title-box {
				max-width: ac(620px, 430px);
			}
		}
		&--job {
			.hero__title-box {
				max-width: ac(620px, 350px);
			}
		}
	}

	@mixin lg {
		&--emp-search,
		&--job-search {
			.hero__form-btn {
				width: 18.42%;
			}
		}
	}

	@mixin xxl {
		&--article {
			.hero__bg {
				transform: translateX(
					calc(-50% + ac(700px, 313px, 1440, 2460))
				);
				width: ac(1514px, 814px, 1440, 2460);
				background-color: var(--cl-blue);

				img {
					width: auto;
					object-fit: unset;
				}
			}
		}
	}

	@mixin max-lg {
		&__form-flex {
			flex-direction: column;
		}

		&--job-search .hero__form-keyword,
		&__form-keyword,
		&__form-btn,
		&__form-location,
		&__form-sectors {
			width: 100%;
		}

		&__form-btn {
			margin: 12px auto 0;
		}
	}

	@mixin max-md {
		&--article {
			padding-bottom: 20px;

			.hero__bg {
				margin-top: 30px;
				padding: 0 20px;
			}
		}

		&--emp,
		&--land {
			.hero__bg {
				img {
					object-position: left;
				}
			}
		}

		&--profile {
			.hero__img-box {
				max-width: 400px;
				margin: 15px auto 0;
			}
		}

		&__form-flex {
			flex-direction: column;
		}
	}

	@mixin max-sm {
		&--home,
		&--emp-search,
		&--job-search {
			&__cloud {
				display: none;

				&.mob {
					display: block;
				}
			}
		}

		&__form-btn {
			max-width: unset;
		}
	}
}
