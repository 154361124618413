.values {
  background: var(--cl-light-gray);
  padding-top: ac(86px, 48px);
  padding-bottom: ac(86px, 48px);
  &-wrap {
    overflow: hidden;
  }           
  &__title {
    font-size: ac(48px, 32px);
    margin-bottom: ac(40px, 24px);
    color: var(--cl-dark-blue);
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
  }
  &-card {
    border: 1px solid rgba(70, 159, 222, 0.4);
    border-radius: ac(20px, 18px);
    background: #FFF;
    max-width: 404px;
    /* min-height: 396px; */
    padding-top: ac(32px, 24px);
    padding-right: ac(24px, 18px);
    padding-bottom: ac(32px, 24px);
    padding-left: ac(24px, 18px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    @media (max-width: 620px) {
      max-width: none;
      min-height: auto;
      max-height: 420px;
    }
    &__decoration {
      position: absolute;
      inset: 0;
      z-index: 0;
    }
    &__image {
      margin-bottom: ac(24px, 20px);
      border: 1px solid #E6E6E6;
      width: ac(140px, 95px, 992);
      height: ac(140px, 95px, 992);
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ac(12px, 6px);
      position: relative;
      z-index: 2;
      @media (max-width: 992px) {
        width: ac(95px, 64px, 600, 992);
        height: ac(95px, 64px, 600, 992);
      }
      img {

      }
    }
    &__title {
      margin-bottom: ac(24px, 18px, 992);
      font-size: ac(32px, 20px);
      font-weight: 800;
      color: var(--cl-dark-blue);
      position: relative;
      z-index: 2;
      font-family: var(--font-title);
    }
    &__descr {
      font-size: ac(20px, 16px);
      line-height: 1.4;
      color: var(--cl-dark-gray);
      text-align: center;
      position: relative;
      z-index: 2;
      p {
        max-height: 200px;
        scrollbar-width: thin;
        overflow: auto;
      }
    }
  }

  &__slider-nav {
   .btn-icon-only {
     margin-top: ac(40px, 30px);
   }
  }
}