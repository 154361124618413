.employers-list {
	margin-top: ac(86px, 48px);

	&__title-box {
		margin-bottom: ac(48px, 20px);
	}

	&__title {
		color: var(--cl-dark-blue);
	}

	&__wrapper {
		width: 100%;
		gap: ac(54px, 20px, 1024);
	}

	&__col {
		width: 100%;
	}

	&__card {
		border-radius: 16px;
		border: 1px solid #d1d1d1;
		background-color: #f3f7fc;

		.buttons {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: ac(18px, 14px);
		}

		.btn {
			flex-shrink: 0;

			&.btn-save {
				padding: 0;
			}
		}

		&.blur-type {
			position: relative;
			filter: blur(3px);
			pointer-events: none;

			&:after {
				position: absolute;
				inset: 0;
				border-radius: inherit;
				background: var(--cl-gray);
				content: "";
				z-index: 1;
				opacity: .1;
				border: 1px solid rgba(209, 209, 209, 1);
			}

			.employers-list__info-box {
				filter: blur(4px);
				pointer-events: none;
			}
		}
	}

	&__logo {
		width: 199px;
		min-height: 198px;
		padding: 30px 20px;
		border-top-left-radius: 16px;
		background-color: var(--cl-white);
		border-right: 1px solid #d1d1d1;

		img {
			object-fit: contain;
		}

	}

	&__info-box {
		padding: 40px ac(24px, 15px) ac(40px, 20px);
	}

	&__name {
		margin-bottom: 16px;
		font-size: ac(26px, 20px);
		line-height: ac(34px, 26px);
		font-weight: 600;
	}

	&__row {
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		&:last-child {
			margin-top: 16px;
		}

		span {
			display: flex;
			align-items: center;
			color: var(--cl-gray);
			margin-right: 6px;
			&::before {
				content: '';
				flex-shrink: 0;
				margin-right: 8px;
				width: 24px;
				height: 24px;
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23757575' viewBox='0 0 260 188'%3E%3Cpath d='M134.3,109.2L202,83.8V146c0,13.4-32.2,24.3-72,24.3S58,159.4,58,146V83.8l67.9,25.4l4.2,1.6L134.3,109.2z M130.1,98L258,50 L130.1,2L2,50l20,7.5v81.8c-4.7,2.2-8,7.1-8,12.7v34h28v-34c0-5.7-3.3-10.6-8.1-12.8V61.9L130.1,98z'/%3E%3C/svg%3E")
				center / contain no-repeat;
			}
		}

		&--location {
			span {
				&::before {
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23757575' d='M12 1.5a8.26 8.26 0 0 0-8.25 8.25c0 7.06 7.5 12.39 7.82 12.614a.75.75 0 0 0 .86 0c.32-.223 7.82-5.555 7.82-12.614A8.26 8.26 0 0 0 12 1.5Zm0 5.25a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z'/%3E%3C/svg%3E")
					center / contain no-repeat;
				}
			}
		}
		&--sectors {
			span {
				&:before {
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23757575' d='M22.475 19.5H21V9a1.5 1.5 0 0 0-1.5-1.5h-4.125a.375.375 0 0 0-.375.375V19.5h-1.5V3.038a1.54 1.54 0 0 0-.578-1.218 1.5 1.5 0 0 0-1.755-.065l-7.5 4.995A1.5 1.5 0 0 0 3 8v11.5H1.525a.767.767 0 0 0-.775.7.75.75 0 0 0 .75.8h21a.748.748 0 0 0 .75-.8.767.767 0 0 0-.775-.7Zm-15.35-2.25a.75.75 0 0 1-.8.75.767.767 0 0 1-.7-.776v-1.449a.768.768 0 0 1 .7-.775.75.75 0 0 1 .8.75v1.5Zm0-5.25a.75.75 0 0 1-.8.75.768.768 0 0 1-.7-.776v-1.449a.768.768 0 0 1 .7-.775.75.75 0 0 1 .8.75V12Zm3.75 5.25a.75.75 0 0 1-.8.75.766.766 0 0 1-.7-.774v-1.45a.768.768 0 0 1 .7-.775.749.749 0 0 1 .8.75v1.499Zm0-5.25a.75.75 0 0 1-.8.75.768.768 0 0 1-.7-.774v-1.45a.768.768 0 0 1 .7-.775.75.75 0 0 1 .8.75V12Z'/%3E%3C/svg%3E")
						center / contain no-repeat!important;
				}
			}
		}
		&--education {
			span {
				&::before {
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23757575' viewBox='0 0 260 188'%3E%3Cpath d='M134.3,109.2L202,83.8V146c0,13.4-32.2,24.3-72,24.3S58,159.4,58,146V83.8l67.9,25.4l4.2,1.6L134.3,109.2z M130.1,98L258,50 L130.1,2L2,50l20,7.5v81.8c-4.7,2.2-8,7.1-8,12.7v34h28v-34c0-5.7-3.3-10.6-8.1-12.8V61.9L130.1,98z'/%3E%3C/svg%3E")
					center / contain no-repeat;
				}
			}
		}
	}

	&__row-link {
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		transition: .3s;
		color: var(--cl-dark-blue);

		&:hover {
			color: var(--cl-blue);

		}
	}

	&__tag {
		border-radius: 4px;
		border: 1px solid var(--cl-blue);
		font-size: 14px;
		padding: 1px 8px;
	}

	&__bottom {
		padding: ac(25px, 15px) ac(31px, 15px) ac(29px, 15px);
		border-top: 1px solid #d1d1d1;
		gap: 10px;
	}

	&__descr {
		margin-right: 25px;
		max-height: calc(24px * 3);
		font-size: 16px;
		line-height: 24px;
		color: var(--cl-dark-gray);
		@mixin max-line-length 3;
	}

	&__filter-box {
		width: 100%;
		display: none;
	}

	&__filter-title {
		margin-bottom: ac(22px, 15px);
		font-size: ac(32px, 22px);
		line-height: ac(40px, 30px);
		color: var(--cl-dark-blue);
		font-weight: 800;
		font-family: var(--font-title);
	}

	&__form {
		flex-direction: column;
		align-items: flex-start;
	}

	&__item {
		margin-bottom: ac(25px, 15px);
		margin-top: ac(23px, 15px);
		font-size: ac(20px, 18px);
		line-height: ac(28px, 26px);
		font-weight: 800;
		font-family: var(--font-title);
	}

	&__checkbox {
		margin-left: 2px;
		&:not(:first-child) {
			margin-top: 8px;
		}
		cursor: pointer;

		input:checked + {
			.employers-list__check {
				background-color: var(--cl-dark-blue);
			}
		}

		&:hover {
			.employers-list__check {
				border-color: var(--cl-dark-blue);
			}
		}
	}

	&__checkbox-box {
		padding-top: ac(24px, 10px);
		gap: 12px;
	}

	&__check {
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 16px;
		height: 16px;
		border-radius: 2px;
		border: 2px solid var(--cl-dark-blue);
		transition: 0.3s ease;
		flex-shrink: 0;

		&::after {
			content: '';
			width: 10px;
			height: 7px;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill='%23fff' d='M1.212 4.288A.94.94 0 0 1 2.54 2.96l1.21 1.212L7.462.459A.94.94 0 1 1 8.79 1.788L4.415 6.163a.938.938 0 0 1-1.328 0L1.212 4.288Z'/%3E%3C/svg%3E")
				center / contain no-repeat;
		}
	}

	&__label {
		font-size: 16px;
		line-height: 22px;
		transition: 0.3s ease;
	}

	&__layout {
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: var(--cl-white);
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;
		z-index: 1101;

		&.active {
			opacity: 0.3;
			visibility: visible;
			cursor: pointer;
		}
	}

	&__burger-box {
		margin-left: auto;
		align-items: center;
		font-size: 20px;
		gap: 10px;
		cursor: pointer;
		transition: 0.3s ease;
		z-index: 1;
		display: none;

		&:hover {
			color: var(--cl-dark-blue);

			.employers-list__burger {
				border-color: var(--cl-dark-blue);

				span {
					background-color: var(--cl-dark-blue);
				}
			}
		}
	}

	&__burger {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		width: 34px;
		height: 34px;
		background: transparent;
		border-radius: 100%;
		border: 1px solid var(--cl-black);
		transition: 0.3s ease;

		span {
			pointer-events: none;
			width: 17px;
			height: 2px;
			background-color: var(--cl-black);
			transition: 0.3s ease;

			&:nth-child(2) {
				width: 13px;
				margin: 3px 0;
			}
			&:nth-child(3) {
				width: 7px;
			}
		}
	}
	.pagination {
		margin: ac(49px, 30px) auto 0;
	}

	&__flag {
		border-radius: 0 0 6px 6px;
		background-color: var(--cl-blue);
		color: var(--cl-white);
		padding: 5px 10px;
		font-size: 12px;
		font-weight: 500;
		padding: 10px 8px;
	}

	@mixin lg {

		.cont {
			max-width: ac(942px, 650px, 1024);
		}

		&__filter-box {
			overflow: hidden;
		}
		&__col,
		&__title-box {
			/*max-width: ac(942px, 650px, 1024);*/
			width: 100%;
		}
		&__burger-box {
			display: none;
		}

		.simplebar-content-wrapper,
		.simplebar-mask {
			overflow: visible !important;
		}
	}

	@mixin max-lg {
		&__filter-box {
			position: fixed;
			top: 0px;
			left: 0;

			margin-top: 0;
			padding: 20px;
			width: 100%;
			max-width: 400px;

			background: var(--cl-white);
			transform: translateX(-100%);
			opacity: 0;
			visibility: hidden;
			transition: 0.3s ease;
			z-index: 1111;

			height: calc((var(--vh) * 100) - 0px);

			.simplebar-track {
				background-color: rgb(8, 2, 35, 0.2) !important;
			}

			.simplebar-scrollbar::before {
				background-color: var(--cl-dark-blue) !important;
				opacity: 1 !important;
			}

			&.active {
				transform: translateX(0%);
				opacity: 1;
				visibility: visible;

				.employers-list__burger {
					position: absolute;
					top: 20px;
					right: 20px;
					width: 35px;
					height: 35px;
					span {
						&:nth-child(2) {
							width: 0;
							opacity: 0;
						}

						&:nth-child(1) {
							width: 19px;
							transform: rotate(45deg) translate(3px, 3px);
						}

						&:nth-child(3) {
							width: 19px;
							transform: rotate(-45deg) translate(4px, -4px);
						}
					}
				}
			}
		}
	}

	@mixin max-md {
		&__bottom {
			flex-wrap: wrap;
			justify-content: flex-end;
		}

		&__row {
			flex-direction: column;
			span {
				margin-bottom: 5px;
			}
		}

		&__logo {
			width: 150px;
			min-height: 150px;
			padding: 15px 10px;
		}
	}

	@mixin max-sm {
		&__head {
			flex-direction: column;
		}
		&__logo {
			aspect-ratio: 3 / 2;
			width: 100%;
			height: 100px;
			min-height: 100px;
			border-radius: 15px 15px 0 0;
			border-right: 0;
			border-bottom: 1px solid #d1d1d1;

			img {
				max-width: 150px;
				margin: 0 auto;
			}
		}
	}
}
