.details {
	margin-top: ac(64px, 40px);

	&--job,
	&--profile {
		margin-top: ac(87px, 40px);

		.newsletter__form {
			margin-top: 18px;
		}

		.details__wrapper {
			gap: ac(86px, 25px, 1025);
		}
		.details__sidebar {
			padding-top: 0;
		}
		.details__col-title {
			font-size: ac(32px, 22px);
			line-height: ac(40px, 30px);
			font-weight: 800;
		}
		.details__text {
			h4,
			h5,
			h6 {
				&:not(:first-child) {
					margin-top: ac(24px, 18px);
				}
				&:not(:last-child) {
					margin-bottom: ac(16px, 10px);
				}
			}

			ul,
			ol {
				&:not(:first-child) {
					margin-top: ac(17px, 15px);
				}
			}
		}
	}

	&--profile {
		h3 {
			color: var(--cl-dark-blue);
		}
	}

	&--job {
		.details__title-xs {
			margin-top: ac(42px, 20px);
			font-size: ac(24px, 20px);
			line-height: ac(40px, 28px);
			font-weight: 800;
			color: var(--cl-dark-blue);
			font-family: var(--font-title);
		}

		.details__info {
			span {
				&::before {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
	&__tag {
		padding: 6px 8px;
		border-radius: 6px;
		font-size: 14px;
		line-height: 15px;
		font-weight: 500;
		color: var(--cl-black);
		border: 1px solid var(--cl-blue);
	}
	&__info {
		gap: 6px;
		color: #757575;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		&:not(:first-child) {
			margin-top: ac(22px, 10px);
		}

		span {
			opacity: 0.7;

			&::before {
				content: '';
				margin-right: 12px;
				flex-shrink: 0;
				width: 20px;
				height: 20px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		&--country {
			span:before {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23757575' d='M17.5 3.75v9.375a.625.625 0 0 1-.216.469C16.091 14.627 14.95 15 13.861 15c-1.477 0-2.857-.683-4.14-1.316-2.075-1.028-3.878-1.918-5.971-.264v3.455a.625.625 0 0 1-1.25 0V3.75a.625.625 0 0 1 .216-.469C5.53.845 8.053 2.093 10.278 3.194c2.144 1.06 4.002 1.978 6.188.087a.625.625 0 0 1 1.034.469Z'/%3E%3C/svg%3E");
			}
		}
		&--location {
			span:before {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23757575' d='M10 1.25a6.883 6.883 0 0 0-6.875 6.875c0 5.883 6.25 10.326 6.516 10.512a.625.625 0 0 0 .718 0c.266-.186 6.516-4.63 6.516-10.512A6.883 6.883 0 0 0 10 1.25Zm0 4.375a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z'/%3E%3C/svg%3E");
			}
		}
		&--job {
			span:before {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23757575' d='M12 8.875a.625.625 0 0 1-.625.625h-2.5a.625.625 0 0 1 0-1.25h2.5a.625.625 0 0 1 .625.625Zm6.25-3.125v10A1.25 1.25 0 0 1 17 17H3.25A1.25 1.25 0 0 1 2 15.75v-10A1.25 1.25 0 0 1 3.25 4.5h3.125v-.625A1.875 1.875 0 0 1 8.25 2H12a1.875 1.875 0 0 1 1.875 1.875V4.5H17a1.25 1.25 0 0 1 1.25 1.25ZM7.625 4.5h5v-.625A.625.625 0 0 0 12 3.25H8.25a.625.625 0 0 0-.625.625V4.5ZM17 9V5.75H3.25V9A14.376 14.376 0 0 0 17 9Z' /%3E%3C/svg%3E");
			}
		}
		&--sectors {
			span:before {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23757575' d='M18.728 16.25H17.5V7.5a1.25 1.25 0 0 0-1.25-1.25h-3.437a.313.313 0 0 0-.313.313v9.687h-1.25V2.532a1.284 1.284 0 0 0-.48-1.016 1.25 1.25 0 0 0-1.463-.054l-6.25 4.163a1.25 1.25 0 0 0-.557 1.042v9.583H1.271a.639.639 0 0 0-.647.584.625.625 0 0 0 .625.666h17.5a.625.625 0 0 0 .625-.666.639.639 0 0 0-.646-.584ZM5.937 14.375A.624.624 0 0 1 5.27 15a.639.639 0 0 1-.584-.647v-1.207a.64.64 0 0 1 .584-.646.626.626 0 0 1 .666.625v1.25Zm0-4.375a.624.624 0 0 1-.666.625.64.64 0 0 1-.584-.647V8.771a.64.64 0 0 1 .584-.646.625.625 0 0 1 .666.625V10Zm3.125 4.375a.624.624 0 0 1-.666.625.64.64 0 0 1-.584-.645v-1.209a.64.64 0 0 1 .584-.645.626.626 0 0 1 .666.625v1.249Zm0-4.375a.624.624 0 0 1-.666.625.64.64 0 0 1-.584-.645V8.77a.64.64 0 0 1 .584-.645.625.625 0 0 1 .666.625V10Z' /%3E%3C/svg%3E");
			}
		}
		&--date {
			span:before {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23757575' d='M15.625 5.91V3.124a1.25 1.25 0 0 0-1.25-1.25h-8.75a1.25 1.25 0 0 0-1.25 1.25v2.813a1.255 1.255 0 0 0 .5 1L8.959 10l-4.084 3.063a1.255 1.255 0 0 0-.5 1v2.812a1.25 1.25 0 0 0 1.25 1.25h8.75a1.25 1.25 0 0 0 1.25-1.25v-2.784a1.255 1.255 0 0 0-.496-.997L11.037 10l4.092-3.093a1.257 1.257 0 0 0 .496-.998Zm-1.7 7.84H6.04L10 10.781l3.924 2.969Zm.45-7.84L10 9.218 5.625 5.938V3.124h8.75v2.784Z' /%3E%3C/svg%3E");
			}
		}
	}

	&__wrapper {
		gap: ac(138px, 25px, 1025);
	}

	&__title {
		margin-bottom: ac(40px, 20px);
		color: var(--cl-dark-blue);
	}

	&__text {
		img {
			margin-top: ac(39px, 20px);
			position: relative;
			border-radius: 20px;
			overflow: hidden;
			filter: brightness(0.8);
			height: auto;
			width: auto;
		}

		h1 {
			font-size: ac(66px, 36px);
			line-height: ac(78px, 42px);
			margin-top: 0.8em;
			margin-bottom: 0.4em;
			font-weight: 800;
		}

		h2 {
			&:not(:first-child) {
				margin-top: ac(40px, 25px);
			}
			&:not(:last-child) {
				margin-bottom: ac(24px, 20px);
			}

			color: var(--forest);
			font-size: ac(56px, 32px);
			font-weight: 800;
			line-height: ac(68px, 36px);
		}

		h3 {
			&:not(:first-child) {
				margin-top: ac(38px, 24px);
			}
			&:not(:last-child) {
				margin-bottom: ac(24px, 15px);
			}
			font-size: ac(32px, 26px);
			line-height: ac(40px, 32px);
			font-weight: 800;
			font-family: var(--font-title);
		}

		h4,
		h5,
		h6 {
			color: var(--cl-dark-blue);
			font-family: var(--font-title);
			font-weight: 800;

			&:not(:first-child) {
				margin-top: ac(30px, 20px);
			}
			&:not(:last-child) {
				margin-bottom: ac(20px, 15px);
			}
		}

		h4 {
			font-size: ac(24px, 21px);
			line-height: ac(28px, 28px);
		}

		h5 {
			font-size: ac(22px, 20px);
			line-height: ac(30px, 28px);
		}

		h6 {
			font-size: ac(20px, 19px);
			line-height: ac(28px, 24px);
		}

		& > ul,
		& > ol {
			&:not(:last-child) {
				margin-bottom: ac(40px, 20px);
			}
		}

		& > span {
			font: ac(20px, 16px)/ac(28px, 20px) var(--font-main) !important;
			color: var(--cl-black) !important;

			span {
				font: inherit !important;

				span {
					font: inherit !important;

					span {
						font: inherit !important;

						span {
							font: inherit !important;
						}
					}
				}
			}

			/*strong {
				font: ac(32px, 26px)/ac(40px, 32px) var(--font-title) !important;
				font-weight: 800 !important;
				text-transform: none !important;
			}*/

		}

		p,
		li {
			font-size: ac(20px, 16px);
			line-height: ac(28px, 20px);

			&:not(:last-child) {
				margin-bottom: ac(12px, 6px);
			}
		}

		p,
		li {
			color: var(--cl-dark-gray);

			font-weight: 400;

			a {
				display: inline-block;
				color: var(--cl-dark-blue);
				word-break: break-word;
				font-size: inherit;
				font-weight: 400;
				transition: 0.3s ease;

				&:hover {
					opacity: 0.7;
				}
			}

			span {
				font: inherit !important;

				span {
					font: inherit !important;

					span {
						font: inherit !important;

						span {
							font: inherit !important;
						}
					}
				}
			}
		}

		ul,
		ol {
			&:not(:first-child) {
				margin-top: ac(23px, 15px);
			}
		}

		ul {
			& > li {
				position: relative;
				padding-left: ac(36px, 30px);

				&:not(:last-child) {
					margin-bottom: ac(16px, 6px);
				}

				&::before {
					content: '';
					position: absolute;
					top: ac(13px, 9px);
					transform: translateY(-50%);
					left: 0;
					width: 18px;
					height: 18px;
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='16' fill='none'%3E%3Cpath fill='%234291CC' d='M17.293 11.113a7.244 7.244 0 0 1-1.315 2.429 6.36 6.36 0 0 1-2.483 1.812c-1.067.443-2.19.683-3.347.641a9.554 9.554 0 0 1-3.479-.786 6.052 6.052 0 0 1-2.038-1.448 4.542 4.542 0 0 1-1.136-2.218c-.073-.356-.079-.71-.094-1.057.013-1.317.319-2.54 1.11-3.603a5.342 5.342 0 0 1 2.97-2.023c.824-.222 1.658-.241 2.492-.14 1.473.173 2.83 1.114 3.176 2.719.233 1.082.15 2.126-.487 3.071-.412.609-1.017.968-1.722 1.144a4.392 4.392 0 0 1-2.622-.147c-.542-.198-.83-.871-.615-1.41.214-.539.859-.806 1.408-.593.331.13.675.222 1.038.2.637-.036 1.023-.233 1.16-.878.102-.486.13-.979-.1-1.447-.294-.598-.817-.855-1.438-.96a4.848 4.848 0 0 0-1.643-.005 3.804 3.804 0 0 0-1.72.786c-.863.675-1.282 1.56-1.285 2.638 0 .439-.037.879.082 1.316.135.514.41.982.794 1.354.58.568 1.294.898 2.07 1.098 1.25.322 2.495.35 3.75-.027 1.736-.524 2.781-1.687 3.31-3.366a6.812 6.812 0 0 0 .163-3.537c-.252-1.175-.793-2.199-1.708-3.005-.87-.766-1.895-1.237-3.018-1.528a9.179 9.179 0 0 0-2.799-.268A8.72 8.72 0 0 0 3.535 3.21c-.637.4-1.256.82-1.786 1.358-.211.214-.419.428-.568.692a.813.813 0 0 1-.147.168c-.227.223-.39.249-.62.103-.167-.106-.23-.377-.146-.604.18-.482.468-.897.804-1.283C2.104 2.464 3.37 1.593 4.784.927 6.392.173 8.078-.109 9.86.037A9.623 9.623 0 0 1 13.3.974c1.459.706 2.649 1.722 3.427 3.17.578 1.07.87 2.218 1 3.419.134 1.217-.06 2.393-.433 3.55Z'/%3E%3C/svg%3E")
						center / contain no-repeat;
				}

				span {
					font: inherit !important;

					span {
						font: inherit !important;

						span {
							font: inherit !important;

							span {
								font: inherit !important;
							}
						}
					}
				}
			}
		}

		ol {
			list-style-type: decimal;
			padding-left: 28px;

			&:not(:last-child) {
				margin-bottom: ac(12px, 6px);
			}
		}
	}

	&__share {
		font-size: 18px;
		line-height: 28px;
		font-weight: 700;
		color: var(--cl-dark-blue);
	}

	&__contact {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 36px;
		border: 1px solid var(--cl-blue);
		border-radius: 4px;
		transition: 0.3s ease;

		i {
			color: var(--cl-dark-blue);
			transition: 0.3s ease;
		}

		&:hover {
			background-color: var(--cl-dark-blue);
			i {
				color: var(--cl-white);
			}
		}
	}

	&__col-title {
		font-family: var(--font-title);
		font-size: ac(24px, 20px);
		line-height: ac(40px, 30px);
		font-weight: 800;
		color: var(--cl-dark-blue);
	}

	&__row {
		margin-top: 15px;
		padding: 6px;
		border-radius: 12px;
		background-color: var(--cl-light-gray);
		border: 1px solid rgba(70, 158, 222, 0.3);
	}

	&__photo {
		border: 1px solid var(--cl-dark-blue);
	}

	&__name {
		font-size: 20px;
		line-height: 22px;
		font-weight: 600;
	}

	&__position {
		margin-top: 11px;
		font-size: 16px;
		font-weight: 500;
		color: #4291cc;
	}

	&__author-text {
		font-size: 16px;
		margin-top: 11px;

		p, li {
			font-size: 16px;

			a {
				font: inherit;
			}
		}

		&[data-simplebar] {
			padding-right: 10px;
			max-height: 169px;
			width: 100%;

			.simplebar-track.simplebar-vertical {
				width: 4px;
				background: var(--pp-light-gray);
				border-radius: 4px;

				.simplebar-scrollbar {
					background: var(--cl-dark-blue);
					border-radius: 4px;

					&:before {
						content: none;
					}
				}
			}
		}
	}

	.newsletter__form input {
		border-color: #469ede;
		background-color: #f5f5f5;
	}

	@mixin lg {
		&__text-box {
			max-width: ac(726px, 550px, 1024);
		}

		&__sidebar {
			padding-top: 40px;
		}
	}

	@mixin max-lg {
	}

	&:last-of-type {
		margin-bottom: ac(86px, 40px);
	}
}
