.multiple-select {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: ac(54px, 46px);
    padding: ac(15px, 10px) ac(17px, 12px) !important;
    background: #f5f5f5;
    border: 1px solid #469ede80;
    border-radius: 6px;
    transition: all 0.25s ease;
    position: relative;
    cursor: pointer;
    /*z-index: 20;*/

    &:before {
        border: none;
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        top: 50%;
        width: 16px;
        height: 16px;
        aspect-ratio: 1;
        background: var(--cl-gray);
        translate: 0 -40%;
        margin-top: 0;
        right: 16.5px;

        mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.8315L19.422 6.45495C19.5604 6.31261 19.7258 6.19909 19.9087 6.12099C20.0917 6.04289 20.2884 6.00178 20.4875 6.00006C20.6866 5.99834 20.8841 6.03604 21.0684 6.11097C21.2526 6.1859 21.42 6.29655 21.5608 6.43647C21.7016 6.57639 21.8128 6.74278 21.8882 6.92592C21.9637 7.10906 22.0016 7.3053 21.9999 7.50317C21.9981 7.70104 21.9568 7.89659 21.8782 8.07839C21.7996 8.2602 21.6854 8.42464 21.5422 8.56212L13.0601 16.9923C12.7789 17.2716 12.3976 17.4286 12 17.4286C11.6024 17.4286 11.2211 17.2716 10.9399 16.9923L2.45775 8.56212C2.31454 8.42464 2.20031 8.2602 2.12173 8.07839C2.04315 7.89659 2.00179 7.70104 2.00006 7.50317C1.99833 7.3053 2.03626 7.10906 2.11166 6.92592C2.18705 6.74278 2.29838 6.57639 2.43917 6.43647C2.57995 6.29655 2.74736 6.1859 2.93163 6.11097C3.1159 6.03604 3.31335 5.99834 3.51244 6.00006C3.71153 6.00178 3.90828 6.04289 4.09121 6.12099C4.27415 6.19909 4.43959 6.31261 4.57791 6.45495L12 13.8315Z' fill='black'/%3E%3C/svg%3E%0A");
        mask-repeat: no-repeat;

        transition: all ease 250ms;
    }

    input {
        font-size: ac(16px, 14px);
        color: var(--cl-black);
        @mixin max-line-length-one;
        background: transparent;
        cursor: pointer;

        &::placeholder {
            color: var(--cl-black);
        }
    }

    .checkBox {
        color: var(--cl-white) !important;
    }

    &.open {

        &:before {
            margin-top: 0;
            rotate: 180deg;
        }

        .dropdown {
            display: block;
        }

        .multiple-select__mask {
            display: block;
        }
    }

    &__mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    .dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        padding: 12px;
        width: 100%;
        background: #fff;
        border: 1px solid #ddd;
        margin-top: -1px;
        border-bottom-left-radius: 2.5px;
        border-bottom-right-radius: 2.5px;
        transition: 0.3s ease-in;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
        z-index: 10;

        max-height: 250px;
        overflow-y: auto;

        display: none;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--cl-dark-blue);
        }
    }

    .checkBox {
        transition: background-color 0.3s ease;

        &:hover {
            background-color: var(--cl-blue-gray);
        }
    }
}

.filters-box {
    /* Custom checkbox */
    .checkBox {
        grid-column: 1 / -1;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: fit-content;
        margin-left: 2px;

        span {
            padding-left: 36px;
            position: relative;
            display: block;
            flex: 0 0 auto;
            /*font-size: 14px;*/
            color: var(--cl-black);
            transition: all 0.3s ease-in-out;
            word-break: break-word;
            width: 100%;
            font-size: 16px;
            line-height: 22px;

            &:before {
                content: "";
                position: absolute;
                width: 16px;
                height: 16px;
                border: 2px solid var(--cl-dark-blue);
                border-radius: 2px;
                left: 0;
                top: 4px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 8px;
                color: var(--white);
            }

            /*a {
                text-transform: none;
                color: var(--red);
                &:hover {
                    text-decoration: underline;
                }
            }*/
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            &:checked + span {
                &:before {
                    content: "✔";
                    background: var(--cl-dark-blue);
                    border: 1px solid var(--cl-dark-blue);
                }
            }
        }

        &:hover {
            /*span:before {
                border: 1px solid var(--cl-blue);
            }*/
        }
    }

    .checkBox {
        width: 100%;

        span {
            color: var(--cl-black);
            font-size: 16px;
            line-height: 22px;
            padding-left: 26px;

            &:before {
                color: var(--cl-white);
            }
        }
    }

    .checkBox {
        padding: 4px 0;

    }

    .filters-parent  {
        margin-bottom: 0;

        &--inner {
            padding: 0 0 0 5px;
        }

        .filters-trigger {
            margin: 0 8px 0 0;
        }
    }

    .filters-children {
        padding: 0 0 0 20px;
    }
}