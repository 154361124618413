.hiring-employers {
  overflow: hidden;

  padding: ac(64px, 41px) 0 ac(64px, 32px);

  background: var(--cl-light-gray);

  .cont {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 48px;

    @mixin media 951 {
      flex-direction: column;
      gap: 30px;
    }
  }

  @mixin media 551 {
    padding-bottom: 0;
  }
}

.hiring-employers__left-block {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  max-width: 512px;

  background: linear-gradient(
    to right,
    var(--cl-light-gray) 90%,
    transparent 100%
  );

  @mixin media 951 {
    width: 100%;
    max-width: 550px;
  }
}

.hiring-employers__title {
  margin-bottom: ac(40px, 25px);

  color: var(--cl-blue);

  font-size: ac(48px, 36px);
  font-weight: 600;
  line-height: 1.5;

  @mixin media 551 {
    letter-spacing: 0.2px;
  }
}

.hiring-employers__count-wrap {
  display: flex;
  gap: 7px;

  margin-bottom: ac(40px, 20px);

  color: var(--cl-dark-blue);

  font-family: var(--font-title);
  font-size: 20px;
  font-weight: 800;
  line-height: 1.6;
}

.hiring-employers__count {
  padding: 11px;
  border-radius: 6px;

  background: var(--cl-blue);
  color: var(--cl-white);

  @mixin media 769 {
    display: inline-block;

    padding: 8px 11px;

    line-height: 1;
  }
}

.hiring-employers__count-text {
  display: flex;
  align-items: center;
  gap: 7px;

  &::before {
    content: '';
    display: inline-block;

    width: 16px;
    height: 1px;

    background: var(--cl-gray);
  }
}

.hiring-employers__nav {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: ac(20px, 10px);
}

.hiring-employers__slider-nav {
  display: flex;
  gap: ac(12px, 8px);
}

.hiring-employers__slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;

  border: 1px solid var(--cl-dark-blue);
  border-radius: 6px;

  color: var(--cl-blue);
}

.hiring-employers__right-block {
  --hidding-gradient-width: 80px;

  width: 100%;

  max-width: ac(866px, 650px, 1024);

  margin-right: -129px;

  clip-path: inset(
    -100% -100% -100% calc((var(--hidding-gradient-width)) * -1)
  );

  @mixin media 951 {
    max-width: 100%;
    margin-right: 0;

    clip-path: unset;
  }
}

.hiring-employers-slider {
  --slide-height: 172px;
  --slide-gap: 16px;

  .swiper-wrapper {
    max-height: calc(var(--slide-height) * 3 + var(--slide-gap) * 2);
    /* justify-content: center; */
    /* align-items: center; */

    @mixin media 901 {
      justify-content: unset;

      margin-bottom: 35px;
    }
  }

  @mixin media 769 {
    /* max-width: 172px; */
    margin: 0 auto;

    --slide-height: ac(120px, 106px, 550, 768);
    --slide-gap: 8px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--slide-height) - var(--slide-gap));
    margin-bottom: var(--slide-gap);
    /* width: 364px; */

    @mixin media 901 {
      width: fit-content;
    }

    @mixin media 451 {
      width: 100%;
    }
  }

  @mixin media 451 {
    --slide-height: 160px;
  }
}

.hiring-employers-slider__slide {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: 10px;
  border-radius: 12px;
  border: 1px solid rgba(70, 158, 222, 0.3);

  transition: all ease 250ms;

  &.active,
  &:hover {
    background: var(--cl-white);

    .hiring-employers-slider__logo {
      filter: none;
    }

    &::before {
      opacity: 1;
    }
  }

  &.active {
    box-shadow: 0px 0px 28px 0px #469ede3d;
  }

  &::before {
    content: attr(data-count);
    position: absolute;
    top: 0;
    left: 16px;

    padding: 11px;
    border-radius: 0 0 6px 6px;

    font-family: var(--font-title);
    font-size: 20px;
    font-weight: 800;
    line-height: 1;

    color: var(--cl-white);
    background: var(--cl-blue);

    opacity: 0;

    transition: opacity 250ms ease;

    @mixin media 551 {
      padding: 6px;

      font-size: 16px;
    }
  }
}

.hiring-employers-slider__logo {
  width: 100%;
  max-width: ac(145px, 110px);
  max-height: ac(71px, 66px);
  object-fit: contain;

  img {
    object-fit: contain;
  }

  transition: filter ease 250ms;
  filter: grayscale(1);
}

.hiring-employers__btn {
  @mixin media 769 {
    padding: 13px 22px;
  }
}
