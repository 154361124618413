.view-jobs-by {
  overflow: hidden;

  padding: ac(64px, 40px) 0 ac(86px, 16px);

  background: var(--cl-light-gray);
}

.view-jobs-by__title {
  margin-bottom: ac(21px, 18px);

  @mixin media 451 {
    text-align: left !important;
  }
}

.view-jobs-by__selector {
  display: flex;

  width: fit-content;

  margin: 0 auto ac(48px, 32px);
  padding: 6px;
  border: 1px solid rgba(70, 158, 222, 0.3);
  border-radius: 12px;

  background: var(--cl-white);

  @mixin media 451 {
    width: 100%;
  }
}

.view-jobs-by__selector-item {
  padding: 13px 32px;
  border-radius: 6px;

  font-weight: 600;
  line-height: 1;

  color: var(--cl-dark-blue);

  transition: all ease 250ms;

  &:hover {
    color: var(--cl-blue);
  }

  &.active {
    background: var(--cl-dark-blue);
    color: var(--cl-white);
  }

  @mixin media 451 {
    width: 50%;
  }
}

.view-jobs-by__slider {
  max-width: 260px;

  margin: 0 auto;

  @media (min-width: 1100px) {
    max-width: 810px;
  }

  @media (min-width: 1600px) {
    max-width: calc(100% + 120px);
    margin-left: -60px;
    width: calc(100% + 120px);

    .swiper-button-disabled {
      display: none !important;
    }
  }

  @mixin media 551 {
    max-width: 233px;
  }

  @mixin media 451 {
    margin: unset;
  }
}

.job-group-card {
  overflow: hidden;

  width: 100%;

  border: 1px solid var(--cl-blue);
  border-radius: 12px;

  &:hover {
    .job-group-card__bottom-block {
      img {
        scale: 1.1;
      }
    }
  }
}

.job-group-card__top-block {
  display: flex;
  flex-direction: column;

  height: 152px;

  padding: ac(24px, 20px) 16px 16px ac(28px, 24px);

  @mixin media 551 {
    height: 136px;
    padding-bottom: 21px;
  }
}

.job-group-card__title {
  font-family: var(--font-title);
  font-size: ac(22px, 20px);
  font-weight: 800;
  line-height: 1.27;
  letter-spacing: -0.3px;
}

.job-group-card__count-wrap {
  display: flex;
  align-items: center;
  gap: 0.5ch;

  margin-top: auto;

  font-family: var(--font-title);
  font-weight: 800;
  font-size: ac(20px, 18px);
}

.job-group-card__count-text {
  display: flex;
  align-items: center;
  gap: 0.6ch;

  color: var(--cl-dark-blue);

  &::after {
    content: '';
    display: inline-block;

    width: 16px;
    height: 1px;

    background: var(--cl-gray);
  }
}

.job-group-card__count {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 32px;
  height: 32px;

  border-radius: 6px;

  color: var(--cl-white);
  background: var(--cl-blue);
}

.job-group-card__bottom-block {
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: end;
  justify-content: end;

  height: ac(173px, 154px);

  padding: 16px;

  background: linear-gradient(
    180deg,
    rgba(66, 145, 204, 0) 0%,
    rgba(66, 145, 204, 0.6) 100%
  );

  img {
    transition: scale ease 250ms;
  }
}

.job-group-card__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.job-group-card__btn.btn.sm {
  @mixin media 551 {
    padding: 12px 12px 12px 20px;
  }
}

.view-jobs-by__slider-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ac(12px, 8px);

  margin-top: ac(39px, 15px);

  @mixin media 451 {
    justify-content: start;
  }
}
