.popup-upload-cv {
	position: relative;
	max-width: 850px;
	max-height: 85dvh;
	padding: 0;
	border: 1px solid var(--Gray-III);

	& > .simplebar-track.simplebar-vertical {
		top: 55px;
		.simplebar-scrollbar::before {
			opacity: 0.3 !important;
			background-color: var(--black);
		}
	}

	&__wrapper {
		display: flex;
		padding: ac(45px, 40px) ac(35px, 15px) ac(30px, 20px);
		border: 1px solid var(--Gray-III);
		background-color: var(--white);
	}

	&__title {
		max-width: 380px;
		font-size: ac(36px, 24px);
		line-height: ac(42px, 30px);
		font-weight: 600;
	}

	&__close {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -1px;
		right: -1px;
		width: 56px;
		height: 56px;
		border: 1px solid var(--forest);
		background-color: var(--forest);
		transition: 0.3s ease;
		cursor: pointer;
		z-index: 5;

		&::after {
			content: '';
			width: 24px;
			height: 24px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%231b54a1' d='M23.19.951a1.875 1.875 0 0 1 0 2.652L14.793 12l8.397 8.397a1.875 1.875 0 0 1-2.651 2.651l-8.397-8.397-8.397 8.397a1.875 1.875 0 0 1-2.652-2.651L9.49 12 1.093 3.603A1.875 1.875 0 1 1 3.745.95l8.397 8.397L20.539.951a1.875 1.875 0 0 1 2.651 0Z'/%3E%3C/svg%3E");
			cursor: pointer;
			transition: 0.3s ease;
		}

		&:hover {
			border-color: var(--forest);
			background-color: var(--Lime-III);

			&::after {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23469ede' d='M23.19.951a1.875 1.875 0 0 1 0 2.652L14.793 12l8.397 8.397a1.875 1.875 0 0 1-2.651 2.651l-8.397-8.397-8.397 8.397a1.875 1.875 0 0 1-2.652-2.651L9.49 12 1.093 3.603A1.875 1.875 0 1 1 3.745.95l8.397 8.397L20.539.951a1.875 1.875 0 0 1 2.651 0Z'/%3E%3C/svg%3E");
			}
		}
	}

	.touch__input-box,
	.agreement,
	.meta-upload-file {
		flex: 1 0 ac(300px, 250px, 320);
	}

	.dashboard__form .login__input-box {
		flex: 1 0 ac(300px, 250px, 320);
	}

	.agreement {
		margin-top: ac(9px, 0px);
		max-width: 391px;

		.checkBox {
			span {
				padding-left: 38px;
				font-weight: 700;

				&::before {
					width: 20px;
					height: 20px;
					border-width: 2px;
					border-radius: 2px;
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' fill='none'%3E%3Cpath fill='%23fff' d='M.541 7.875a1.8 1.8 0 0 1-.4-.585 1.763 1.763 0 0 1 .4-1.964 1.89 1.89 0 0 1 2.614 0l2.383 2.325L12.844.528A1.873 1.873 0 0 1 14.151 0c.49 0 .96.19 1.308.528.346.338.541.796.541 1.274s-.195.936-.541 1.274l-8.612 8.395a1.85 1.85 0 0 1-.6.392 1.887 1.887 0 0 1-2.015-.392L.542 7.875Z'/%3E%3C/svg%3E")
						center / 10px 8px no-repeat;
				}
			}
		}
	}

	.footer__upload-box {
		position: relative;
		display: flex;
		align-items: flex-start;
		cursor: pointer;

		input {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
		}

		div {
			margin-right: 15px;
			border-radius: 5px;
			padding: 0 ac(20px, 10px);
			line-height: 44px;
			font-weight: 600;
			color: var(--cl-black);
			border: 1px solid var(--cl-blue);
			background: var(--cl-white);
			transition: 0.3s ease;
		}

		&:hover {
			div {
				color: var(--cl-white);
				background-color: var(--cl-blue);
			}

			.footer__upload-text {
				color: var(--cl-blue);
			}
		}
	}

	.footer__upload-text {
		line-height: 48px;
		max-height: 48px;
		font-weight: 600;
		color: var(--cl-black);
		transition: 0.3s ease;
		@mixin max-line-length 1;
	}

	.dashboard__btn--blue {
		&:hover {
			background-color: var(--cl-white);
			border-color: var(--cl-blue);
			color: var(--cl-dark-blue);
		}
	}
	@mixin max-lg {
		.footer__upload-box {
			flex-direction: row;
			justify-content: flex-start;
		}

		.footer__upload-text {
			margin: 0 10px;
		}
	}
	@mixin max-md {
		max-width: 500px;
	}

	@mixin max-sm {
		.footer__upload-box {
			flex-direction: column;
			align-items: center;

			& > div {
				margin-right: 0;
			}
		}
	}
}
