.contact-section {
  position: relative;
  padding-top: ac(86px, 14px);
  padding-bottom: ac(86px, 48px);
  background: #F5F5F5;
  .cont {
    max-width: 1300px;
    width: perc(1300);
  }
  &__decoration {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    @media (max-width: 924px) {
      display: none;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;
    @media (max-width: 924px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__image {
    max-width: ac(728px, 580px, 924);
    @media (max-width: 924px) {
      max-width: 728px;
      margin-right: 0;
      margin-bottom: ac(38px, 24px);
    }
    img {
      border-radius: 20px;
    }
  }
  &__text {
    max-width: ac(524px, 380px, 924);
    margin-left: ac(42px, 14px, 924);
    @media (max-width: 924px) {
      max-width: 728px;
    }
  }
  &__title {
    font-size: ac(48px, 28px);
    line-height: 1.2;
    font-weight: 600;
    color: var(--cl-dark-blue);
    margin-bottom: ac(24px, 20px);
  }
  &__descr {
    font-size: ac(20px, 18px);
    line-height: 1.4;
    margin-bottom: ac(32px, 20px);
    color: #35353F;
    font-weight: 400;
    p {
      font-size: ac(20px, 18px);
      &:not(:last-child) {
        margin-bottom: ac(12px, 6px);
      }
    }
  }
  &__socials {

  }
  &__social {
    display: flex;
    align-items: center;
    margin-bottom: ac(16px, 14px);
    width: fit-content;
    &:last-child {
      margin-bottom: 0;
    }
    i {
      color: var(--cl-dark-blue);
      transition: .3s all;
      min-width: ac(38px, 35px);
      min-height: ac(38px, 34px);
      @media (max-width: 576px) {
        transform: scale(0.9);
      }
    }
    span {
      margin-left: ac(12px, 8px);
      color: var(--cl-dark-blue);
      font-size: ac(24px, 20px);
      font-weight: 400;
      text-decoration: underline;
      text-decoration-color: var(--cl-dark-blue);
      line-height: 1.18;
    }
    &:hover {
      i {
        opacity: .85;
      }
      span {
        text-decoration: none;
      }
    }
  }
}