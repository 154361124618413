.login {
	padding-top: ac(168px, 100px);
	padding-bottom: ac(86px, 40px);
	background: linear-gradient(111.34deg, #469ede 0%, #4291cc 100%);

	.login-portal__form--restore {
		min-height: 55vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		grid-row-gap: ac(30px, 24px);
	}

	&--sign {
		opacity: 0;
		transition: opacity .1s ease;
	}

	&__title {
		color: var(--cl-white);
	}

	&__thumb-box {
		margin-top: ac(40px, 20px);
		max-width: 288px;
		overflow: hidden;
	}

	&__thumb {
		background-color: var(--cl-white);
		padding: 6px;
		border: 1px solid rgba(70, 158, 222, 0.3);
		border-radius: 12px;
		opacity: 0;

		&.swiper-initialized {
			opacity: 1;
		}

		.swiper-slide {
			padding: 10px 0;
			border: 1px solid var(--cl-white);
			border-radius: 6px;
			cursor: pointer;
			text-align: center;
			color: var(--cl-dark-blue);
			font-size: 16px;
			line-height: 20px;
			font-weight: 600;
			transition: 0.3s ease;
			max-width: 135px;
			&:hover {
				border-color: var(--cl-dark-blue);
			}

			&-thumb-active {
				border-color: var(--cl-dark-blue);
				background-color: var(--cl-dark-blue);
				color: var(--cl-white);
			}
		}
	}

	&__slider-box {
		margin-top: ac(40px, 20px);

		.swiper-slide {
			opacity: 0 !important;

			&-active {
				opacity: 1 !important;
			}
		}
	}

	&__form {
		padding: ac(48px, 20px) ac(48px, 15px);
		background-color: var(--cl-white);
		border: 1px solid rgba(70, 158, 222, 0.3);
		border-radius: 20px;

		&:not(.login__form--login) {
			.login__input-box {
				flex: 1 0 200px;
			}
		}
	}

	&__label {
		margin-bottom: 8px;
		font-weight: 500;
		font-size: 14px;
		line-height: 15px;
		color: #757575;

		b {
			color: #4291cc;
		}
	}

	&__input-box {

		&.w-mini-box {
			width: ac(140px, 110px, 1278) !important;
		}

		&.multiple-old-select {
			.choices__button {
				border: 0;
				width: 14px;
				height: 14px;
				padding: 0;
				opacity: 1;
				margin: 0;
				margin-left: 5px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath fill='%23757575' d='M11.402 10.473a.657.657 0 1 1-.93.93L7 7.93l-3.473 3.472a.657.657 0 0 1-.93-.93L6.07 7 2.598 3.527a.657.657 0 1 1 .93-.93L7 6.071l3.473-3.474a.657.657 0 0 1 .93.93L7.929 7l3.473 3.473Z'/%3E%3C/svg%3E");
				transition: 0.3s ease;

				&:hover {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath fill='%23469ede' d='M11.402 10.473a.657.657 0 1 1-.93.93L7 7.93l-3.473 3.472a.657.657 0 0 1-.93-.93L6.07 7 2.598 3.527a.657.657 0 1 1 .93-.93L7 6.071l3.473-3.474a.657.657 0 0 1 .93.93L7.929 7l3.473 3.473Z'/%3E%3C/svg%3E");
				}
			}

			.choices__input {
				background: #f5f5f5 !important;
			}

			.choices__list--multiple .choices__item {
				margin: 6px 6px 6px 0px;
				padding: 8px;
				font-size: 14px;
				line-height: 14px;
				color: var(--cl-white);
				background-color: #141d24;
				border-radius: 6px;
				border: 0;
			}
			.choices__inner {
				padding: ac(14px, 6px) ac(17px, 12px);
				background: #f5f5f5;
				border: 1px solid #469ede80;
				border-radius: 6px;
			}
			input[type='search'] {
				min-width: 13ch !important;
			}
		}

		input:not(.choices__input),
		textarea {
			padding: ac(15px, 10px) ac(17px, 12px);
			background: #f5f5f5;
			border: 1px solid rgba(70, 158, 222, 0.5);
			border-radius: 6px;
			width: 100%;
			border-radius: 6px;
			@mixin transparent-bg-input var(--cl-black);

			&.error {
				border-color: red !important;
			}

			&,
			&::placeholder {
				color: var(--cl-black);
				font-size: 16px;
				line-height: 24px;
				font-weight: 400;
				transition: 0.3s ease;
			}

			&::placeholder {
				opacity: 1;
			}
		}

		&.select .choices__inner {
			padding: ac(15px, 10px) ac(17px, 12px) !important;

			.choices__list--single {
				.choices__item {
					font-size: 16px;

					@media (min-width: 1200px) {
					  letter-spacing: -0.01em;
					}
				}
			}
		}

		textarea {
			height: ac(176px, 150px);
			resize: none;
			scrollbar-width: thin;
			scrollbar-color: var(--cl-blue) rgba(30, 30, 30, 0.2);
			overflow-x: hidden;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 6px;
			}

			&::-webkit-scrollbar-track {
				background: rgba(30, 30, 30, 0.2);
				border-radius: 0;
			}
			&::-webkit-scrollbar-thumb {
				background: var(--cl-blue);
				border-radius: 0;
			}
		}

		.cke_1.cke_chrome {
			border-color: rgba(70, 158, 222, 0.5) !important;
			border-radius: 6px;
			overflow: hidden;

			&.error {
				border-color: red !important;
			}
		}

		.cke_1 .cke_top {
			border-bottom-color: rgba(70, 158, 222, 0.5) !important;
		}

		.cke_1 .cke_bottom {
			border-top-color: rgba(70, 158, 222, 0.5) !important;
		}

		.cke_inner {
			background: #f5f5f5;
		}
	}

	&__btn-box {
		& > span {
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			color: #757575;
		}
	}

	&__linkedin {
		& > span {
			display: grid;
			place-items: center;
			background-color: var(--cl-dark-blue);
			border-radius: 2px;
			width: 22px;
			height: 22px;
			transition: 0.3s ease;

			& > span {
				width: 16px;
				color: var(--cl-white);
				transition: 0.3s ease;
			}
		}
		&:hover {
			& > span {
				background-color: var(--cl-white);
				& > span {
					color: var(--cl-dark-blue);
				}
			}
		}
	}

	&__bottom {
		font-size: 14px;
		font-weight: 600;
		line-height: 24px;
		& > * {
			color: var(--cl-dark-blue);
			text-decoration: underline;
			cursor: pointer;
			transition: 0.3s ease;

			&:hover {
				opacity: 0.8;
			}
		}

		a {
			font: inherit;
		}
	}

	&__forgot {
		color: var(--cl-dark-blue);
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-decoration: underline;
		cursor: pointer;
	}
}

@mixin md {
	.login {
		&__form {
			max-width: ac(716px, 600px);

			&--login {
				max-width: 500px;
			}
		}
	}
}
