.pool {
	margin-top: ac(86px, 40px);

	&__wrapper {
		gap: ac(72px, 25px, 1024);
	}

	&__title {
		font-size: ac(40px, 36px);
		line-height: ac(56px, 40px);
		color: var(--cl-dark-blue);
	}

	&__stat-box {
		margin-top: 17px;
	}

	&__stat {
		position: relative;
		margin-right: 32px;
		font-size: ac(20px, 16px);
		line-height: ac(32px, 22px);
		font-family: var(--font-title);
		font-weight: 800;
		padding: 1px 10px;
		color: var(--cl-white);
		flex-shrink: 0;
		background-color: #4291cc;
		border-radius: 6px;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			right: -25px;
			width: 16px;
			height: 1px;
			background-color: #757575;
		}
	}

	&__label {
		font-size: 20px;
		line-height: 32px;
		color: var(--cl-dark-blue);
		font-family: var(--font-title);
		font-weight: 800;
	}

	&__btn-box {
		margin-top: ac(34px, 20px);
	}

	@mixin lg {
		&__title {
			margin-top: ac(43px, 20px);
		}

		.employers-list__card {
			max-width: ac(728px, 400px);
		}

		.employers-list__info-box {
			padding: ac(24px, 15px);
		}
	}
}
