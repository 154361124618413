.jobs {
	margin-top: ac(86px, 40px);
	background-color: #f5f5f5;

	&__wrapper {
		padding: ac(64px, 40px) 0 ac(86px, 40px);
	}

	&__title {
		span {
			color: var(--cl-dark-blue);
		}
	}

	&__nav {
		.btn {
			background-color: var(--cl-white);
		}
	}

	@mixin md {
		&__slider {
			margin-top: ac(26px, 0px);
		}
	}
}
