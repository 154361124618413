@font-face {
	font-family: 'Visby CF';
	src: url('../../assets/fonts/VisbyCF-Bold.eot');
	src: local('../../assets/fonts/Visby CF Bold'),
		local('../../assets/fonts/VisbyCF-Bold'),
		url('../../assets/fonts/VisbyCF-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/VisbyCF-Bold.woff2') format('woff2'),
		url('../../assets/fonts/VisbyCF-Bold.woff') format('woff'),
		url('../../assets/fonts/VisbyCF-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Visby CF';
	src: url('../../assets/fonts/VisbyCF-Light.eot');
	src: local('../../assets/fonts/Visby CF Light'),
		local('../../assets/fonts/VisbyCF-Light'),
		url('../../assets/fonts/VisbyCF-Light.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/VisbyCF-Light.woff2') format('woff2'),
		url('../../assets/fonts/VisbyCF-Light.woff') format('woff'),
		url('../../assets/fonts/VisbyCF-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Visby CF Extra';
	src: url('../../assets/fonts/VisbyCF-ExtraBold.eot');
	src: local('../../assets/fonts/Visby CF Extra Bold'),
		local('../../assets/fonts/VisbyCF-ExtraBold'),
		url('../../assets/fonts/VisbyCF-ExtraBold.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/VisbyCF-ExtraBold.woff2') format('woff2'),
		url('../../assets/fonts/VisbyCF-ExtraBold.woff') format('woff'),
		url('../../assets/fonts/VisbyCF-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Visby CF';
	src: url('../../assets/fonts/VisbyCF-Heavy.eot');
	src: local('../../assets/fonts/Visby CF Heavy'),
		local('../../assets/fonts/VisbyCF-Heavy'),
		url('../../assets/fonts/VisbyCF-Heavy.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/VisbyCF-Heavy.woff2') format('woff2'),
		url('../../assets/fonts/VisbyCF-Heavy.woff') format('woff'),
		url('../../assets/fonts/VisbyCF-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Visby CF';
	src: url('../../assets/fonts/VisbyCF-Medium.eot');
	src: local('../../assets/fonts/Visby CF Medium'),
		local('../../assets/fonts/VisbyCF-Medium'),
		url('../../assets/fonts/VisbyCF-Medium.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/VisbyCF-Medium.woff2') format('woff2'),
		url('../../assets/fonts/VisbyCF-Medium.woff') format('woff'),
		url('../../assets/fonts/VisbyCF-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Visby CF Demi';
	src: url('../../assets/fonts/VisbyCF-DemiBold.eot');
	src: local('../../assets/fonts/Visby CF Demi Bold'),
		local('../../assets/fonts/VisbyCF-DemiBold'),
		url('../../assets/fonts/VisbyCF-DemiBold.eot?#iefix')
			format('embedded-opentype'),
		url('../../assets/fonts/VisbyCF-DemiBold.woff2') format('woff2'),
		url('../../assets/fonts/VisbyCF-DemiBold.woff') format('woff'),
		url('../../assets/fonts/VisbyCF-DemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

h1 {
	font-family: var(--font-title);
	font-weight: 800;

	font-size: ac(64px, 40px);
	line-height: ac(80px, 45px);
}

h2 {
	font-size: ac(48px, 36px);
	line-height: ac(64px, 40px);
	font-weight: 600;

	@mixin max-xs {
		font-size: 26px;
		line-height: 30px;
	}

	a {
		display: inline-block;
		font: inherit;
		color: var(--cl-dark-blue);
		transition: all .3s ease;

		&:hover {
			color: var(--cl-blue);
		}
	}
}

h3 {
	font-size: ac(25px, 22px);
	line-height: normal;
	font-weight: 600;

	a {
		display: inline-block;
		font: inherit;
		color: var(--cl-dark-blue);
		transition: all .3s ease;

		&:hover {
			color: var(--cl-blue);
		}
	}
}

h4 {
	font-size: ac(20px, 18px);
	line-height: 140%;
	font-weight: 400;

	@mixin max-sm {
		line-height: 155%;
	}
}

h5 {
	font-size: ac(28px, 20px);
	font-weight: 500;
	line-height: 1.5;
}

h6 {
	font-size: ac(24px, 18px);
	font-weight: 500;

	line-height: 2;
}

p {
	font-size: ac(18px, 16px);
	font-weight: 400;
	color: var(--cl-medium-grey);

	line-height: 144%;

	@mixin max-sm {
		line-height: 162%;
	}
}

li {
	font-size: ac(18px, 16px);
	font-weight: 400;
	color: var(--cl-medium-grey);

	line-height: 144%;

	@mixin max-sm {
		line-height: 162%;
	}
}

a {
	font-size: ac(20px, 16px);
	font-weight: 500;
	display: inline-block;
	line-height: 100%;
}

.content-element {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	}

	h4 {
		color: var(--cl-medium-grey);
		font-weight: 500;

		&:not(:last-child) {
			margin-bottom: ac(20px, 16px);
		}
	}

	ol,
	ul {
		li {
			list-style-position: inside;
		}
	}

	ol {
		list-style: decimal;
	}

	ul {
		list-style: disc;
	}

	li,
	p {
		/*Not Design*/
		&:not(:last-child) {
			margin-bottom: ac(20px, 16px);
		}
	}
}

.dark-bg {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li {
		color: var(--cl-white);
	}
}
