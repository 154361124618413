.insights-list {
	margin-top: ac(86px, 40px);
	&__title {
		margin-bottom: ac(10px, 0px);
	}

	&__filter {
		.btn {
			margin-top: 0;
			padding: ac(16px, 12px) 10px;
		}
	}

	&__result {
		margin-top: ac(53px, 0px);
	}

	.featured-jobs__pagination {
		margin-top: ac(54px, 15px);
	}

	.insight-card {
		background: #fff;
		&__img-wrap {
			height: ac(270px, 250px, 320);
		}

		&__btn {
			width: 100%;
			max-width: ac(126px, 160px);
			justify-content: space-between;
		}

		&__description {
			&,
			& > * {
				line-height: 24px;
			}
			max-height: calc(24px * 4);
			@mixin max-line-length 4;
		}

		&:hover {
			background: #f3f7fc;

			.insight-card__btn {
				max-width: 100%;
			}
		}
	}

	@mixin md {
		&__filter {
			max-width: ac(572px, 375px);
		}

		.hero__form-keyword {
			max-width: ac(404px, 200px);
		}
	}

	@mixin max-sm {
		.insight-card__content-wrap {
			height: auto;
		}
	}
}
