.featured-jobs {
	padding: ac(60px, 36px) 0 ac(64px, 36px);
}

.featured-jobs__top-wrap {
	display: flex;
	justify-content: space-between;
	align-items: end;

	margin-bottom: ac(48px, 40px);

	@mixin media 551 {
		flex-direction: column;
		gap: 7px;
		align-items: start;

		.select {
			width: 100%;
		}
	}
}

.featured-jobs__card-list {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: ac(26px, 16px) ac(24px, 16px);

	@mixin media 769 {
		grid-template-columns: minmax(0, 1fr);
	}
}

.job-card {
	position: relative;

	display: flex;
	flex-direction: column;

	border: 1px solid #efefef;
	padding: 40px ac(32px, 15px) ac(32px, 15px) ac(40px, 16px);
	border-radius: 16px;

	background: var(--cl-white);

	transition: background ease 250ms;

	&:hover {
		background: #f3f7fc;
	}

	@mixin media 769 {
		padding-top: 49px;
		display: grid;
		grid-template-rows: auto auto minmax(0, 1fr) auto;
	}
}

.job-card__tags {
	position: absolute;
	top: 1px;
	left: ac(40px, 14px);
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: calc(100% - ac(60px, 24px));
	gap: ac(12px, 10px);

	.job-card__tag {
		position: relative;
		top: auto;
		left: auto;
		margin: 0 !important;
	}
}

.job-card__tag {
	position: absolute;
	top: 1px;
	left: ac(40px, 14px);

	padding: 12px 8px;
	border-radius: 0 0 6px 6px;

	color: var(--cl-white);
	background: var(--cl-blue);

	font-size: 12px;
	font-weight: 500;
	line-height: 1.08;
}

.job-card__top-wrap {
	display: flex;
	justify-content: space-between;
	align-items: start;
}

.job-card__title {
	max-width: 386px;
	padding-top: ac(23px, 0px);
	color: var(--cl-dark-blue);
	font-family: var(--font-title);
	font-size: ac(32px, 24px);
	font-weight: 800;
	line-height: 1.15;

	@mixin media 769 {
		letter-spacing: -0.2px;
	}
}

.job-card__logo-wrap {
	display: flex;
	justify-content: center;
	align-items: center;

	max-width: ac(132px, 56px);
	height: ac(73px, 40px);

	padding: 0 ac(6px, 0px);
}

.job-card__logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.job-card__info-list {
	display: flex;
	flex-direction: column;
	gap: ac(8px, 6px);

	padding: ac(20px, 15px) 0;

	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
}

.job-card__info-item {
	display: flex;
	/* align-items: center; */
	gap: ac(8px, 6px);
}

.job-card__info-title {
	flex-shrink: 0;

	color: var(--cl-gray);
}

.job-card__description {
	margin-bottom: 31px;

	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.15px;

	max-height: calc(24px * 4);
	@mixin max-line-length 4;

	@mixin media 769 {
		margin-bottom: 8px;

		&.trancate {
			padding: 0 !important;
			display: -webkit-box;
			-webkit-line-clamp: var(--lines);
			-webkit-box-orient: vertical;
			overflow: hidden;
			height: fit-content;
		}
	}

	p, li {
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.15px;
		font-weight: 500;
	}
}

.job-card__btns {
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 7px;

	margin-top: auto;

	.btn {
		border-radius: 6px;
		padding: 12px 17px;

		.btn__icon {
			width: 18px;
		}
	}

	@mixin max-xs {
		width: 100%;
		margin-left: 0;
		flex-wrap: wrap;
		.btn {
			width: 100%;
		}
	}
}

.featured-jobs__pagination {
	display: flex;
	justify-content: center;
	gap: 5px;

	margin-top: ac(48px, 41px);
}

.featured-jobs__pagination-arrow {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 44px;
	height: 44px;
	transition: all ease 250ms;

	color: #2b3345;
	opacity: 0.7;

	&:hover {
		color: var(--cl-blue);
		opacity: 1;
	}
}

.featured-jobs__pagination-page {
	display: flex;
	justify-content: center;
	align-items: center;

	width: ac(44px, 40px);
	height: ac(44px, 40px);

	border: 1px solid transparent;
	border-radius: 6px;

	font-size: 16px;
	font-weight: 500;

	transition: all ease 250ms;

	&:hover {
		color: var(--cl-blue);
	}

	&.active {
		color: #1b54a1;
		border-color: #1b54a1;
	}
}
