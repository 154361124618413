.applications {
	margin-top: ac(86px, 40px);

	&__wrapper {
		padding: 0 ac(108px, 0px, 1024);
	}

	&__list-box {
		padding: ac(48px, 15px) ac(48px, 15px) ac(71px, 15px);
		background-color: var(--cl-light-gray);
		border: 1px solid #e6e6e6;
	}
	&__name {
		margin-bottom: ac(32px, 15px);
		padding-bottom: ac(30px, 10px);
		border-bottom: 1px solid rgba(70, 158, 222, 0.3);

		font-size: ac(40px, 24px);
		line-height: ac(48px, 30px);
		color: var(--cl-dark-blue);
		font-weight: 600;
	}
	&__list {
		li {
			position: relative;
			padding-left: ac(41px, 30px);
			font-size: ac(20px, 16px);
			line-height: ac(28px, 22px);

			&:not(:last-child) {
				margin-bottom: ac(16px, 10px);
			}

			&::before {
				content: '';
				position: absolute;
				top: ac(4px, 2px);
				left: 2px;
				width: ac(20px, 18px);
				height: ac(20px, 18px);
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%231B54A1' fill-rule='evenodd' d='M10 19.75a9.75 9.75 0 1 0 0-19.5 9.75 9.75 0 0 0 0 19.5Zm-.251-5.807 5.416-6.5-1.664-1.386-4.658 5.589-2.41-2.412L4.9 10.766l3.25 3.25.838.838.76-.91Z' clip-rule='evenodd'/%3E%3C/svg%3E")
					center / contain no-repeat;
			}
		}
	}

	&__subtitle {
		font-size: ac(18px, 16px);
		line-height: ac(28px, 22px);
	}

	@mixin md {
		&__list-box {
			max-width: ac(568px, 350px);
		}
	}
}
