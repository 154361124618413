.advertise {
	margin-top: ac(89px, 40px);

	&--land {
		background-color: var(--cl-light-gray);
		padding: ac(87px, 40px) 0;

		.advertise__title {
			color: var(--cl-black);
		}
	}

	&__title {
		color: var(--cl-dark-blue);
	}

	&__name {
		font-size: 24px;
		line-height: 32px;
		font-weight: 800;
		font-family: var(--font-title);
	}

	&__scroll {
		width: calc(100% + 10px);
		padding-right: 5px;
		max-height: calc(24px * 5);

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background-color: rgba(70, 158, 222, 0.2);
		}

		.simplebar-scrollbar::before {
			opacity: 1;
			inset: 0px;
			background-color: var(--cl-blue);
		}
	}

	&__descr {
		line-height: 24px;

		p, li {
			font-size: 16px;
			line-height: 24px;
		}
	}

	@mixin sm {
		&__card {
			width: calc(100% / 2 - 19px);
		}
	}
	@mixin lg {
		&__card {
			width: calc(100% / 3 - 19px);
		}
	}
}
