:root {
	--cl-black: #141d24;
	--cl-white: #ffffff;

	--cl-blue: #469ede;
	--cl-dark-blue: #1b54a1;
	--cl-gray: #757575;
	--cl-light-gray: #f5f5f5;
	--cl-dark-gray: #41484f;
	--cl-blue-gray: #c6ddf1;

	--font-main: 'Archivo', sans-serif;
	--font-title: 'Visby CF', sans-serif;

	--mobile-menu-start-point: 1200;

	--vh: calc(100vh / 100);
}
