.contact-form {
  padding-top: ac(104px, 64px);
  padding-bottom: ac(86px, 42px);
  position: relative;
  overflow: hidden;
  background: #F3F7FC;
  @media (max-width: 1440px) {
    padding-top: ac(104px, 76px);
  }
  &__bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    right: ac(-100px, 0px, 2000, 2560);
    top: ac(-140px, -40px, 2000, 2560);
    @media (max-width: 2000px) {
      top: ac(-40px, 104px, 1440, 2000);
    }
    @media (max-width: 1440px) {
      height: calc(100% - ac(104px, 76px));
      top: ac(104px, 76px);
    }
    @media (max-width: 992px) {
      display: none;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ac(86px, 48px);
    position: relative;
    z-index: 2;
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: ac(86px, 24px);
    }
  }
  &__text {
    max-width: ac(460px, 320px, 992);
    @media (max-width: 992px) {
      margin-bottom: ac(38px, 24px);
      max-width: 768px;
    }
  }
  &__title {
    margin-bottom: ac(32px, 18px);
    font-size: ac(64px, 46px);
    line-height: 1.18;
    color: var(--cl-black);
    font-weight: 800;
  }
  &__subtitle {
    color: var(--cl-dark-blue);
    margin-bottom: ac(16px, 14px);
    font-size: ac(24px, 20px);
    line-height: 1.18;
  }
  &__descr {
    font-size: ac(20px, 18px);
    line-height: 1.4;
    color: var(--cl-dark-gray);
  }
  &__form {
    padding: ac(48px, 20px);
    border-radius: 20px;
    background: #FFF;
    min-width: ac(620px, 480px, 992);
    min-height: ac(533px, 400px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .thanks {
      margin: auto;
      text-align: center;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ac(34px, 24px);
    }

    @media (max-width: 992px) {
      max-width: 768px;
      min-width: auto;
      width: 100%;
    }
    &-row {
      width: 100%;
      margin-bottom: ac(20px, 18px);
      display: flex;
      align-items: center;
      @media (max-width: 565px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
      }
      .input {
        margin-right: ac(20px, 16px);
        @media (max-width: 565px) {
          margin-bottom: 14px;
        }
        &:last-child {
          margin-right: 0;
        }
        &__label {
          span {
            color: var(--cl-dark-blue);
            font-size: 18px;
          }
        }
      }
    }
    .textarea textarea{
      height: ac(112px, 80px);
      resize: none;
      @media (max-width: 565px) {
        margin-bottom: 0;
      }
    }

    &-bottom {
      width: 100%;
      margin-top: ac(40px, 28px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: ac(12px, 8px);
      @media (max-width: 565px) {
        flex-direction: column;
        align-items: flex-start;
        column-gap: 0;
        row-gap: 14px;
      }
      .agreement {
        max-width: ac(310px, 290px);
        @media (max-width: 565px) {
          max-width: none;
        }
        .checkBox span a {
          color: var(--cl-dark-blue);
          text-decoration-color: var(--cl-dark-blue);
          transition: .3s all;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &-submit {
      width: 100%;
      max-width: ac(213px, 180px);
      @media (max-width: 576px) {
        max-width: 480px;
      }
    }
  }
}