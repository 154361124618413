.board {
	margin-top: ac(85px, 40px);
	padding: ac(86px, 40px) 0;
	background-color: #c2ddf5;

	&__subtitle {
		font-size: ac(18px, 16px);
		line-height: ac(28px, 22px);
	}

	&__scroll {
		width: 100%;
		max-height: calc(ac(28px, 22px) * 5);

		.simplebar-track.simplebar-vertical {
			width: 4px;
			background-color: rgba(70, 158, 222, 0.2);
		}

		.simplebar-scrollbar::before {
			opacity: 1;
			inset: 0px;
			background-color: var(--cl-blue);
		}
	}

	&--sec {
		margin-top: 0;
		background-color: var(--cl-light-gray);

		.board__img {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: ac(-90px, -50);
				right: ac(-120px, -70px);
				width: ac(330px, 200px);
				height: ac(330px, 200px);
				border-radius: 100%;

				background: #469ede;
				mix-blend-mode: color;
				filter: blur(100px);
			}
		}
	}
	&:not(.board--land) {
		.board__decor {
			display: none;
		}
	}

	&--land {
		margin-top: 0;
	}

	@mixin md {
		&:not(.board--sec) {
			.board__img {
				max-width: ac(728px, 350px);
			}
		}

		&--sec {
			.board__col {
				max-width: ac(484px, 350px);
			}
		}
	}
}
