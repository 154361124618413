.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	padding: ac(27px, 13px) 0;
	display: flex;
	align-items: center;
	transition: all 0.3s ease-in-out;

	background: var(--cl-white);

	&.active {
		.header__mob-menu-wrap {
			opacity: 1;
			translate: 0;
			.select.minimal .choices__inner {
				padding: 7px 12px 7px 16px!important;
			}
		}

		.burger {
			span {
				opacity: 0;
			}

			&::before {
				top: 50%;
				rotate: 45deg;
				translate: 0 -50%;
			}

			&::after {
				bottom: 50%;
				rotate: -45deg;
				translate: 0 50%;
			}
		}

		.header__mob-menu-dropshadow {
			opacity: 1;
			visibility: visible;
		}
	}
}

.header__logo {
	width: ac(202px, 162px);

	transition: scale ease 250ms;

	&:hover {
		scale: 1.05;
	}
}

.header__navbar {
	display: flex;
	align-items: center;
}

.header__menu-wrap {
	display: flex;
	align-items: center;

	margin-left: auto;

	@mixin media 551 {
		margin-right: 4px;
	}
}

.menu {
	display: flex;
	align-items: center;
	gap: ac(24px, 12px);

	margin-right: ac(32px, 14px);

	@mixin media 1025 {
		display: none;
	}
}

.header__btns {
	display: flex;
	gap: 8px;

	margin-right: ac(15px, 12px);

	@mixin media 1025 {
		display: none;
	}
}

.header__locale {
	display: flex;
	align-items: center;
	/* gap: 14px; */

	@mixin media 1025 {
		position: relative;
		z-index: 2;
	}

	.country {
		@mixin media 1025 {
			display: none;
		}
	}
}

.menu__link {
	font-size: ac(16px, 14px);
	font-weight: 500;

	transition: all ease 250ms;

	&:hover {
		color: var(--cl-blue);
	}
}

.header__mob-menu-wrap {
	position: fixed;
	top: var(--header-height);
	right: 0;
	z-index: 1;
	max-height: calc((var(--vh) * 100) - 70px);
	display: none;
	grid-template-rows: auto repeat(0, 1fr);

	width: 100vw;
	max-width: 400px;
	height: calc(100vh - var(--header-height));

	padding: 24px 24px 44px;
	border-top: 1px solid var(--cl-gray);

	background: var(--cl-white);

	translate: 100% 0;
	opacity: 0;

	transition: all ease 400ms;

	.simplebar-track.simplebar-vertical {
		width: 4px;
		background-color: rgba(70, 158, 222, 0.2);
	}

	.simplebar-scrollbar::before {
		opacity: 1;
		inset: 0px;
		background-color: var(--cl-blue);
	}

	@mixin media 1025 {
		display: grid;
	}

	@mixin media 551 {
		max-width: unset;
	}

	.select {
		max-width: fit-content;

		@media (max-width: 451px) {
			margin-bottom: 20px;
		}
	}
}

.header__mob-menu-nav {
	margin-top: auto;
	height: 100%;
}

.header__mob-menu-list {
	display: flex;
	flex-direction: column;
	gap: 12px;

	margin-bottom: 32px;
}

.header__mob-menu-link {
	padding: 9px 16px;

	font-size: 20px;
	font-weight: 500;

	transition: color ease 250ms;

	&:hover {
		color: var(--cl-blue);
	}

	&.dropdown {
		&:hover {
			.header__mob-menu-link-dropdown-title {
				&::after {
					background: var(--cl-blue);
				}
			}
		}

		.header__mob-menu-link-dropdown-title {
			display: flex;
			align-items: center;
			gap: 7px;

			&::after {
				content: '';

				display: block;
				width: 15px;
				aspect-ratio: 1;
				background: var(--cl-gray);
				mask-repeat: no-repeat;
				mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.8315L19.422 6.45495C19.5604 6.31261 19.7258 6.19909 19.9087 6.12099C20.0917 6.04289 20.2884 6.00178 20.4875 6.00006C20.6866 5.99834 20.8841 6.03604 21.0684 6.11097C21.2526 6.1859 21.42 6.29655 21.5608 6.43647C21.7016 6.57639 21.8128 6.74278 21.8882 6.92592C21.9637 7.10906 22.0016 7.3053 21.9999 7.50317C21.9981 7.70104 21.9568 7.89659 21.8782 8.07839C21.7996 8.2602 21.6854 8.42464 21.5422 8.56212L13.0601 16.9923C12.7789 17.2716 12.3976 17.4286 12 17.4286C11.6024 17.4286 11.2211 17.2716 10.9399 16.9923L2.45775 8.56212C2.31454 8.42464 2.20031 8.2602 2.12173 8.07839C2.04315 7.89659 2.00179 7.70104 2.00006 7.50317C1.99833 7.3053 2.03626 7.10906 2.11166 6.92592C2.18705 6.74278 2.29838 6.57639 2.43917 6.43647C2.57995 6.29655 2.74736 6.1859 2.93163 6.11097C3.1159 6.03604 3.31335 5.99834 3.51244 6.00006C3.71153 6.00178 3.90828 6.04289 4.09121 6.12099C4.27415 6.19909 4.43959 6.31261 4.57791 6.45495L12 13.8315Z' fill='black'/%3E%3C/svg%3E%0A");

				transition: all ease 250ms;
			}
		}

		.header__mob-menu-dropdown-wrap {
			display: flex;
			flex-direction: column;
			align-items: start;

			padding-top: 10px;
			max-height: 0;
			opacity: 0;
			visibility: hidden;

			transition: all ease 250ms;
		}

		.header__mob-menu-dropdown-link {
			position: relative;

			width: 100%;

			padding: 5px 15px;

			color: var(--cl-black);

			font-size: 20px;
			text-align: start;

			transition: all ease 250ms;

			&:hover {
				color: var(--cl-blue);
			}

			&::before {
				content: '';

				position: absolute;
				left: 0;
				top: 50%;
				translate: 0 -50%;

				width: 10px;
				height: 2px;

				background: currentColor;
			}
		}

		&.active {
			.header__mob-menu-link-dropdown-title {
				&::after {
					rotate: 180deg;
				}
			}

			.header__mob-menu-dropdown-wrap {
				max-height: 500px;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.header__mob-menu-btns {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.burger {
	position: relative;

	display: none;

	width: 27px;
	height: 20px;

	margin-right: 11px;

	cursor: pointer;

	&::before,
	&::after,
	span {
		content: '';
		position: absolute;
		left: 0;

		width: 100%;
		height: 2px;
		border-radius: 99px;

		background: var(--cl-black);

		transition: all ease 250ms;
	}

	span {
		top: 50%;
		translate: 0 -50%;
	}

	&::before {
		top: 0;
	}

	&::after {
		bottom: 0;
	}

	@mixin media 1025 {
		display: block;
	}
}

.header__mob-menu-dropshadow {
	position: fixed;
	left: 0;
	top: var(--header-height);
	width: 100vw;
	height: 100vh;

	background: rgba(0, 0, 0, 0.5);

	opacity: 0;
	visibility: hidden;

	transition: all ease 400ms;
}
