.offer {
	margin-top: ac(86px, 40px);

	&__img {
		aspect-ratio: 1 / 1;
	}

	&__decor {
		width: ac(1007px, 450px);
		height: ac(928px, 450px);
		transform: translate(
			calc(-50% - ac(527px, 200px)),
			calc(-50% - ac(126px, 100px))
		);
	}

	&__col {
		h2 {
			margin-bottom: ac(25px, 15px);
			font-size: ac(40px, 36px);
		}
		p {
			&:not(:first-child) {
				margin-top: ac(12px, 5px);
			}
			font-size: ac(20px, 16px);
			line-height: ac(28px, 22px);
		}
	}

	@mixin md {
		&__img {
			max-width: ac(576px, 350px);
		}
	}
}
