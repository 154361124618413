.jobs-list {
	margin-top: ac(86px, 48px);

	&__title-box {
		margin-bottom: ac(48px, 20px);
	}

	&__wrapper {
		width: 100%;
		gap: ac(54px, 20px, 1024);
	}

	&__col {
		width: 100%;
	}

	&__filter-box {
		width: 100%;
		margin-left: -20px;
		padding-left: 20px;
	}

	&__filter-title {
		margin-bottom: ac(33px, 15px);
		font-size: ac(32px, 22px);
		line-height: ac(40px, 30px);
		color: var(--cl-dark-blue);
		font-weight: 800;
		font-family: var(--font-title);
	}

	&__form {
		flex-direction: column;
		align-items: flex-start;
	}

	&__item {
		margin-bottom: ac(25px, 15px);

		&:not(:first-child) {
			margin-top: ac(23px, 15px);
		}
		font-size: ac(20px, 18px);
		line-height: ac(28px, 26px);
		font-weight: 800;
		font-family: var(--font-title);
	}

	&__checkbox {
		margin-left: 2px;
		&:not(:first-child) {
			margin-top: 8px;
		}
		cursor: pointer;

		input:checked + {
			.jobs-list__check {
				background-color: var(--cl-dark-blue);
			}
		}

		&:hover {
			.jobs-list__check {
				border-color: var(--cl-dark-blue);
			}
		}
	}

	&__checkbox-box {
		padding-top: ac(24px, 10px);
		gap: 12px;
	}

	&__check {
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 16px;
		height: 16px;
		border-radius: 2px;
		border: 2px solid var(--cl-dark-blue);
		transition: 0.3s ease;
		flex-shrink: 0;

		&::after {
			content: '';
			width: 10px;
			height: 7px;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill='%23fff' d='M1.212 4.288A.94.94 0 0 1 2.54 2.96l1.21 1.212L7.462.459A.94.94 0 1 1 8.79 1.788L4.415 6.163a.938.938 0 0 1-1.328 0L1.212 4.288Z'/%3E%3C/svg%3E")
				center / contain no-repeat;
		}
	}

	&__label {
		font-size: 16px;
		line-height: 22px;
		transition: 0.3s ease;
	}

	&__layout {
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: var(--cl-white);
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;
		z-index: 1101;

		&.active {
			opacity: 0.3;
			visibility: visible;
			cursor: pointer;
		}
	}

	&__burger-box {
		margin-left: auto;
		align-items: center;
		font-size: 20px;
		gap: 10px;
		cursor: pointer;
		transition: 0.3s ease;
		z-index: 1;

		&:hover {
			color: var(--cl-dark-blue);

			.jobs-list__burger {
				border-color: var(--cl-dark-blue);

				span {
					background-color: var(--cl-dark-blue);
				}
			}
		}
	}

	&__burger {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		width: 34px;
		height: 34px;
		background: transparent;
		border-radius: 100%;
		border: 1px solid var(--cl-black);
		transition: 0.3s ease;

		span {
			pointer-events: none;
			width: 17px;
			height: 2px;
			background-color: var(--cl-black);
			transition: 0.3s ease;

			&:nth-child(2) {
				width: 13px;
				margin: 3px 0;
			}
			&:nth-child(3) {
				width: 7px;
			}
		}
	}
	.pagination {
		margin: ac(49px, 30px) auto 0;
	}

	.job-card {
		align-items: flex-start;
		padding: 20px ac(31px, 15px) ac(31px, 15px);
		justify-items: start;

		@media (max-width: 1200px) {
			padding-top: 40px;
		}

		&__tag {
			position: static;
			margin-top: -21px;
			margin-bottom: ac(0px, 10px);
		}

		&__logo-wrap {
			transform: translateY(-6px);
			max-width: ac(103px, 56px);
			padding: 0 ac(5px, 0px);
		}

		&__title {
			max-width: unset;
		}

		&__top-wrap {
			gap: 30px;
			padding: 0 ac(31px, 15px) 9px;
			width: calc(100% + ac(31px, 15px) + ac(31px, 15px));
			margin-left: ac(-31px, -15px);
			border-bottom: 1px solid #e6e6e6;
		}

		&__bottom {
			gap: 20px;
			margin-top: ac(24px, 15px);
		}

		&__info-list {
			padding: 0;
		}
	}

	@mixin lg {
		&__filter-box {
			overflow: hidden;
			padding-bottom: 60px;
		}
		&__col,
		&__title-box {
			max-width: ac(942px, 650px, 1024);
		}
		&__burger-box {
			display: none;
		}

		.simplebar-content-wrapper,
		.simplebar-mask {
			overflow: visible !important;
		}
	}

	@mixin max-lg {
		&__filter-box {
			position: fixed;
			top: 0px;
			left: 0;

			margin-top: 0;
			padding: 20px;
			width: 100%;
			max-width: 400px;

			background: var(--cl-white);
			transform: translateX(-100%);
			opacity: 0;
			visibility: hidden;
			transition: 0.3s ease;
			z-index: 1111;
			margin-left: 0;
			padding-left: 30px;

			height: calc((var(--vh) * 100) - 0px);

			.simplebar-track {
				background-color: rgb(8, 2, 35, 0.2) !important;
			}

			.simplebar-scrollbar::before {
				background-color: var(--cl-dark-blue) !important;
				opacity: 1 !important;
			}

			&.active {
				transform: translateX(0%);
				opacity: 1;
				visibility: visible;

				.jobs-list__burger {
					position: absolute;
					top: 20px;
					right: 20px;
					width: 35px;
					height: 35px;
					span {
						&:nth-child(2) {
							width: 0;
							opacity: 0;
						}

						&:nth-child(1) {
							width: 19px;
							transform: rotate(45deg) translate(3px, 3px);
						}

						&:nth-child(3) {
							width: 19px;
							transform: rotate(-45deg) translate(4px, -4px);
						}
					}
				}
			}
		}
	}

	@mixin max-md {
	}

	@mixin max-sm {
	}
}

.jobs-accordion {
	position: relative;

	&__icon {
		width: 14px;
		height: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 11px;
		left: -20px;
		cursor: pointer;

		&:before, &:after {
			top: 50%;
			left: 0;
			right: 0;
			position: absolute;
			transition: .2s;
			content: "";
			height: 2px;
			background: var(--cl-dark-blue);
			border-radius: 2px;
		}

		&:after {
			transform: rotate(90deg);
		}

		&.is-open {
			&:after {
				transform: rotate(0deg);
			}
		}
	}

	&__list {
		transition: .3s ease;


		&:not(.is-open) {
			max-height: 0;
			opacity: 0;
			pointer-events: none;
		}

		&.is-open {
			pointer-events: auto;
			opacity: 1;
			max-height: 1000px;
		}
	}

	&:not(.is-children-elements) {
		.jobs-accordion__icon {
			display: none;
		}
	}
}
