.footer {
	padding: ac(86px, 40px) 0 ac(28px, 24px);
	border: 1px solid #e6e6e6;
	z-index: 2;
	background-color: var(--cl-white);

	&--emp {
		margin-top: ac(86px, 40px);
	}
}

.footer__logo {
	width: ac(294px, 203px);

	transition: scale ease 250ms;

	img {
		width: 100%;
		height: 100%;
	}

	&:hover {
		scale: 1.1;
	}
}

.footer__top-block {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: ac(32px, 24px);

	@mixin media 551 {
		flex-direction: column;
		gap: 15px;
	}
}

.footer__socials {
	display: flex;
	align-items: center;
	gap: ac(20px, 10px);

	@mixin media 551 {
		justify-content: center;
		flex-direction: column;
	}
}

.footer__socials-label {
	color: var(--cl-dark-blue);

	font-size: 18px;
	font-weight: 500;
}

.footer__socials-list {
	display: flex;
	align-items: center;
	gap: 8px;
}

.footer__social-item {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 36px;
	height: 36px;
	border-radius: 4px;

	color: var(--cl-white);
	background: var(--cl-dark-blue);
	border: 1px solid var(--cl-dark-blue);

	transition: all ease 400ms;

	&:hover {
		color: var(--cl-dark-blue);
		background: var(--cl-white);
	}

	i {
		width: 18px;
	}
}

.footer__middle-block {
	display: flex;
	gap: ac(28px, 16px);

	padding-bottom: ac(61px, 16px);
	border-bottom: 1px solid rgba(70, 158, 222, 0.5);

	@mixin max-lg {
		flex-direction: column;
	}
}

.footer__title {
	max-width: 404px;

	color: var(--cl-dark-blue);

	font-size: ac(32px, 26px);
	font-weight: 600;
	line-height: 1.375;

	&.mob {
		display: none;

		margin: 0 auto;

		font-family: var(--font-title);
		font-weight: 800;
		text-align: center;
		line-height: 1.54;

		@mixin media 551 {
			display: block;
		}
	}

	@mixin media 551 {
		display: none;
	}
}

.footer__nav {
	display: flex;
	justify-content: space-between;
	gap: 20px;

	width: 100%;
	min-width: max-content;

	@mixin media 769 {
		flex-wrap: wrap;

		min-width: unset;
	}

	@mixin media 551 {
		display: none;
	}
}

.footer__nav-mob {
	display: none;

	justify-content: center;
	gap: 20px;

	@mixin media 551 {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 0;
	}

	.footer__nav-link {
		line-height: 1.71;
	}
}

.footer__nav-block {
	padding-top: 8px;
	margin-bottom: 8px;
}

.footer__nav-block-title {
	margin-bottom: ac(24px, 8px);

	color: var(--cl-dark-blue);

	font-size: ac(18px, 16px);
	font-weight: 500;

	@mixin media 551 {
		text-align: center;
	}
}

.footer__nav-link-list {
	display: grid;
	grid-template-rows: repeat(3, minmax(0, 1fr));
	grid-auto-flow: column;
	justify-items: start;
	gap: ac(20px, 6px) ac(20px, 10px);

	@mixin media 551 {
		grid-template-rows: unset;
		grid-auto-flow: row;
		justify-items: center;
	}
}

.footer__nav-link {
	font-size: ac(16px, 14px);
	font-weight: 500;

	transition: all ease 250ms;

	&:hover {
		color: var(--cl-blue);
	}

	@mixin media 551 {
		text-align: center;
	}
}

.footer__bottom-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;

	padding-top: ac(28px, 26px);

	@mixin media 651 {
		justify-content: center;
		gap: 19px;
	}
}

.footer__copyright {
	color: var(--cl-gray);

	font-size: 14px;
	font-weight: 500;
	@media (max-width: 576px) {
		text-align: center;
	}
}

.footer__terms-policy-link-list {
	display: flex;
	align-items: center;
	gap: 22px ac(48px, 10px);

	@mixin media 501 {
		flex-direction: column;
	}
}

.footer__terms-policy-link {
	color: var(--cl-dark-blue);

	font-size: 14px;
	font-weight: 600;
	text-decoration: underline;
	letter-spacing: 0.1px;
}
