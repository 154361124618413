.our-history {
  padding-top: ac(86px, 48px);
  padding-bottom: ac(86px, 48px);
  &__wrap {
    display: flex;
    justify-content: space-between;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
  &__text {

  }
  &__title {
    font-size: ac(48px, 32px);
    color: var(--cl-black);
    font-weight: 600;
    margin-bottom: ac(24px, 18px);
    line-height: 1.2;
    span {
      color: var(--cl-dark-blue);
    }
  }
  &__descr {
    font-size: ac(20px, 16px);
    line-height: 1.4;
    margin-bottom: ac(32px, 20px);
    max-width: ac(485px, 420px);
  }
  &__btn {
    width: fit-content;
  }

  &__content {
    background: #F3F7FC;
    border: 1px solid #E6E6E6;
    border-radius: ac(24px, 20px);
    padding-top: ac(36px, 18px);
    padding-right: ac(48px, 20px);
    padding-bottom: ac(48px, 20px);
    padding-left: ac(48px, 20px);
    max-width: ac(729px, 540px, 992);
    margin-left: ac(28px, 24px);
    @media (max-width: 992px) {
      margin-left: 0;
      margin-top: ac(38px, 20px, 500, 992);
      max-width: none;
    }
    &-title {
      margin-bottom: ac(24px, 18px);
      color: var(--cl-dark-blue);
      font-size: ac(32px, 24px);
      font-weight: 800;
    }
    &-body {
      font-size: ac(18px, 16px);
      line-height: 1.4;
      color: var(--cl-dark-gray);
      max-height: 675px;
      scrollbar-width: thin;
      overflow: auto;
      p {
        &:not(:last-child) {
          margin-bottom: ac(12px, 6px);
        }
      }
    }
  }
}