.fp-watermark {
	display: none;
}

html {
	scrollbar-width: thin;
	scrollbar-color: var(--cl-blue) transparent;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: var(--cl-light-grey);
		border-radius: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--cl-red);
		border-radius: 0;
	}
}

body {
	position: relative;
	min-width: 320px;
	margin: auto;
	background: var(--cl-white);

	color: var(--cl-black);
	font-family: var(--font-main);

	min-height: calc(100 * var(--vh));
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.swiper-wrapper {
	left: 0 !important;
}

#site {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.main {
	flex-grow: 1;
}

.cont {
	margin: 0 auto;
	max-width: 1268px;
	width: perc(1268);

	@mixin max-lg {
		width: 87%;
	}
}

.swiper {
	width: 100%;
	overflow: visible;
}

iframe,
img,
video,
svg {
	width: 100%;
	height: 100%;
}

img,
video {
	object-fit: cover;
}

svg {
	object-fit: contain;
}

section {
	position: relative;
}

.section__title {
	font-size: ac(48px, 36px);
	font-weight: 600;
	line-height: 1.333;
	letter-spacing: 0.5px;

	&.center {
		text-align: center;
	}
}

.logo {
	transition: 0.3s ease;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&:hover {
		transform: scale(1.06);
	}
}

#bold-credits {
	width: 150px;
	transition: ease-in-out 0.25s;

	&:hover {
		transform: scale(1.1);
	}
}

#site.cookie-terms-page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;

	.main {
		flex-grow: 1;
		padding: 0;
	}
}

.cookie-terms-section {
	padding: ac(250px, 140px) 0 ac(150px, 50px);
	p {
		padding: 0;
		margin-bottom: 10px;
	}
	ul,
	ol {
		padding-left: 20px;
		li {
			p {
				margin-bottom: 0;
			}
		}
	}

	ul {
		list-style: circle;
	}

	ol {
		list-style: decimal;
	}

	a {
		display: inline;
		text-decoration: underline;
		transition: 0.2s;

		&:hover {
			opacity: 0.7;
		}
	}
}

.single-page-404 {
	min-height: 100vh;
	padding: 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__wrapper {
		background-color: var(--cl-white);
		border-radius: 16px;
		border: 1px solid var(--cl-blue);
		padding: ac(30px, 15px) ac(40px, 15px);
	}
	h1 {
		color: var(--cl-dark-blue);
		font-size: ac(64px, 30px, 320);
		line-height: ac(80px, 45px);
	}
	h1,
	p {
		margin-bottom: 15px;
		padding: 0;
	}

	a {
		display: inline-block;
		margin-top: 20px;
	}
}

.select-layout {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&.active {
		display: block;
	}
}

.jconfirm.jconfirm-modern .jconfirm-box {
	@media (max-width: 768px) {
	  width: 91% !important;
	}
}

.snackbar-pos.bottom-left {
	p {
		a {
			font: inherit !important;
			text-decoration: underline;
			transition: .3s;

			&:hover {
				color: var(--cl-blue-gray) !important;
			}
		}
	}
}

