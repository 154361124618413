.benefits {
	margin-top: ac(172px, 60px);

	&__title {
		font-size: ac(40px, 36px);
		line-height: ac(44px, 40px);
		color: var(--cl-dark-blue);
		font-family: var(--font-title);
		font-weight: 800;
	}

	&__card {
		width: calc(100% / 2 - 14px);
	}

	&__num {
		width: ac(54px, 24px, 320);
		height: ac(68px, 30px, 320);
	}

	&__name {
		font-size: ac(24px, 16px);
		line-height: ac(26px, 20px);
		font-weight: 800;
		font-family: var(--font-title);
	}

	@mixin lg {
		&__card {
			width: calc(100% / 3 - 19px);
		}
	}

	@mixin max-xs {
		&__title {
			font-size: 26px;
			line-height: 30px;
		}
	}
}
