.latest-vacancies {
	padding: ac(51px, 20px) 0 ac(66px, 48px);
	background: var(--cl-light-gray);

	&__top-wrap {
		margin-bottom: ac(46px, 38px);
	}

	.swiper-slide {
		height: auto;

		.job-card {
			height: 100%;
		}
	}

	&--land {
		padding: ac(64px, 20px) 0 ac(66px, 48px);
	}

	&__slider-nav {
		margin-left: auto;
		margin-right: auto;
		margin-top: ac(40px, 20px);
		justify-content: center;
	}
}
