.input {
	position: relative;

	display: flex;
	flex-direction: column;
	gap: 6px;

	.input__dropdown {
		visibility: hidden;
		opacity: 0;
	}

	&:focus-within {
		.input__dropdown {
			&:not(:empty) {
				z-index: 1;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.input__label-container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	gap: 6px;
}

.input__info {
	width: 14px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 5;

	i {
		width: 14px;
		background: var(--cl-dark-blue);
		transition: .3s;
	}

	&:hover {
		i {
			background-color: var(--cl-blue);
		}

		.input__info-popup {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.input__info-popup {
	position: absolute;
	bottom: 0;
	right: -4px;
	transform: translateX(100%);
	width: ac(220px, 175px);
	background: var(--cl-blue);
	color: var(--cl-white);
	padding: 10px;
	border-radius: 6px;
	font-size: 12px;
	line-height: normal;
	pointer-events: none;
	z-index: 10;
	opacity: 0;
	transition: .2s;

	@media (max-width: 374px) {
		width: 160px;
	}
}

.input__label {
	color: var(--cl-gray);

	font-size: 14px;
	font-weight: 500;
}

.input__element {
	@mixin transparent-bg-input var(--cl-black);

	padding: ac(14px, 12px) ac(20px, 14px);
	border: 1px solid rgba(70, 158, 222, 0.5);
	border-radius: 6px;

	color: var(--cl-black);
	background: var(--cl-light-gray);

	font-size: ac(16px, 14px);

	&::placeholder {
		color: var(--cl-black);
	}
}

.input__dropdown {
	position: absolute;
	top: calc(100% + 6px);

	display: flex;
	flex-direction: column;

	width: 100%;

	padding: 10px 0;
	border: 1px solid rgba(70, 158, 222, 0.5);
	border-radius: 6px;

	color: var(--cl-black);
	background: var(--cl-light-gray);

	transition: all ease 250ms;

	&:empty {
		opacity: 0;
		visibility: hidden;
	}
}

.input__dropdown-item {
	padding: 10px 20px;

	text-align: left;

	transition: all ease 250ms;

	&:hover {
		background: var(--cl-white);
		color: var(--cl-blue);
	}
}

.select {
	position: relative;

	display: flex;
	flex-direction: column;
	gap: 6px;

	min-width: ac(248px, 200px);

	.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
		overflow-x: hidden;
	}

	.choices {
		&::after {
			border: none;

			display: block;
			width: 16px;
			height: 16px;
			aspect-ratio: 1;
			background: var(--cl-gray);
			translate: 0 -40%;
			margin-top: 0;
			right: 16.5px;

			mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.8315L19.422 6.45495C19.5604 6.31261 19.7258 6.19909 19.9087 6.12099C20.0917 6.04289 20.2884 6.00178 20.4875 6.00006C20.6866 5.99834 20.8841 6.03604 21.0684 6.11097C21.2526 6.1859 21.42 6.29655 21.5608 6.43647C21.7016 6.57639 21.8128 6.74278 21.8882 6.92592C21.9637 7.10906 22.0016 7.3053 21.9999 7.50317C21.9981 7.70104 21.9568 7.89659 21.8782 8.07839C21.7996 8.2602 21.6854 8.42464 21.5422 8.56212L13.0601 16.9923C12.7789 17.2716 12.3976 17.4286 12 17.4286C11.6024 17.4286 11.2211 17.2716 10.9399 16.9923L2.45775 8.56212C2.31454 8.42464 2.20031 8.2602 2.12173 8.07839C2.04315 7.89659 2.00179 7.70104 2.00006 7.50317C1.99833 7.3053 2.03626 7.10906 2.11166 6.92592C2.18705 6.74278 2.29838 6.57639 2.43917 6.43647C2.57995 6.29655 2.74736 6.1859 2.93163 6.11097C3.1159 6.03604 3.31335 5.99834 3.51244 6.00006C3.71153 6.00178 3.90828 6.04289 4.09121 6.12099C4.27415 6.19909 4.43959 6.31261 4.57791 6.45495L12 13.8315Z' fill='black'/%3E%3C/svg%3E%0A");
			mask-repeat: no-repeat;

			transition: all ease 250ms;
		}
		.choices__list {
			&--dropdown {
				z-index: 20;
				padding: 10px;

				.choices__item {
					background: transparent !important;
					transition: .3s;
					&:hover {
						color: var(--cl-dark-blue) !important;
					}

					&:not(:last-child) {
						margin-bottom: 10px;

					}
				}
			}
		}

		&.is-open {
			&::after {
				margin-top: 0;
				rotate: 180deg;
			}
		}

		.choices__list--dropdown .choices__item--selectable,
		.choices__list[aria-expanded] .choices__item--selectable {
			padding: 0;
			max-width: 100%;
			@mixin max-line-length-one;
		}
	}

	&.white {
		.choices__inner {
			background: var(--cl-white);
		}

		.choices__item {
			font-size: ac(16px, 14px);
			font-weight: 500;
		}
	}

	&.minimal {
		min-width: fit-content;

		.choices__inner {
			display: flex;
			align-items: center;

			background: transparent;
			border-color: transparent;

			padding: 7px 12px 7px 7px !important;
		}

		.choices__list--single {
			padding-right: 28px;
		}

		.choices__item {
			padding-right: 0;

			font-weight: 500;
		}

		.choices__item--choice {
			padding-right: 10px;
		}

		.choices__list--dropdown {
			min-width: max-content;
		}
	}

	.choices__inner {
		padding: ac(14px, 12px) ac(20px, 14px) !important;
		background: var(--cl-light-gray);
		border-color: rgba(70, 158, 222, 0.5);
		border-radius: 6px;
		transition: .3s;

		&.error {
			border-color: red !important;
		}
	}

	.choices__list--single {
		padding: 0 16px 0 0;
	}

	.choices__item {
		font-size: ac(16px, 14px);

		color: var(--cl-black);
	}

	.choices__placeholder {
		opacity: 1;
	}
}

.select__label {
	color: var(--cl-gray);

	font-size: 14px;
	font-weight: 500;
}

.choices__list--single,
.choices__list--dropdown {
	[data-value='IR'] {
		&::before {
			background-image: url(../../public/images/iraq.svg);
		}
	}
	[data-value='SY'] {
		&::before {
			background-image: url(../../public/images/syria.svg);
		}
	}

	[data-value='JO'] {
		&::before {
			background-image: url(../../public/images/Flag_of_Jordan.svg.png);
		}
	}

	[data-value='LI'] {
		&::before {
			background-image: url(../../public/images/Flag_of_Libya.svg);
		}
	}

	[data-value='LE'] {
		&::before {
			background-image: url(../../public/images/Flag_of_Lebanon.svg);
		}
	}

	[data-value='jo'],
	[data-value='ly'],
	[data-value='sy'],
	[data-value='lb'] {
		&::before {
			background-color: var(--cl-gray);
		}
	}
}

.choices__list--single,
.choices__list--dropdown {
	[data-value='IR'],
	[data-value='JO'],
	[data-value='LI'],
	[data-value='SY'],
	[data-value='LE'] {
		&::before {
			content: '';
			display: block;
			width: 40px;
			height: 28px;

			border: 1px solid var(--cl-light-gray);
			border-radius: 4px;

			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	.choices__item {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}

.choices-small .choices__list[aria-expanded] .choices__item  {
	font-size: 13px!important;
}

.default-custom-select {
	display: inline-flex;
	align-items: center;
	height: ac(56px, 46px);
	padding: ac(15px, 10px) ac(17px, 12px) !important;
	background: #f5f5f5;
	border: 1px solid #469ede80;
	border-radius: 6px;
	transition: all 0.25s ease;
	position: relative;
	cursor: pointer;
	z-index: 20;

	&.error {
		border-color: red !important;
	}

	&__placeholder {
		font-size: 16px;

		color: var(--cl-black);
		@mixin max-line-length-one;
	}

	&__list {
		position: absolute;
		width: 100%;
		background: #fff;
		border: 1px solid #ddd;
		margin-top: -1px;
		border-bottom-left-radius: 2.5px;
		border-bottom-right-radius: 2.5px;
		bottom: -8px;
		left: -1px;
		right: -1px;
		transform: translateY(100%);
		z-index: 100;
		display: none;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		cursor: default;
		padding: 0 10px;
		max-height: 250px;
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
		will-change: scroll-position;

		.jobs-list__checkbox {
			min-height: 30px;
			margin: 0;
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}

		.jobs-list__label {
			font-size: 14px;
		}
	}

	&.active {
		.default-custom-select__list {
			display: flex;
		}
	}

	&:before {
		border: none;
		content: '';
		display: block;
		pointer-events: none;
		position: absolute;
		top: 50%;
		width: 16px;
		height: 16px;
		aspect-ratio: 1;
		background: var(--cl-gray);
		translate: 0 -40%;
		margin-top: 0;
		right: 16.5px;

		mask-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.8315L19.422 6.45495C19.5604 6.31261 19.7258 6.19909 19.9087 6.12099C20.0917 6.04289 20.2884 6.00178 20.4875 6.00006C20.6866 5.99834 20.8841 6.03604 21.0684 6.11097C21.2526 6.1859 21.42 6.29655 21.5608 6.43647C21.7016 6.57639 21.8128 6.74278 21.8882 6.92592C21.9637 7.10906 22.0016 7.3053 21.9999 7.50317C21.9981 7.70104 21.9568 7.89659 21.8782 8.07839C21.7996 8.2602 21.6854 8.42464 21.5422 8.56212L13.0601 16.9923C12.7789 17.2716 12.3976 17.4286 12 17.4286C11.6024 17.4286 11.2211 17.2716 10.9399 16.9923L2.45775 8.56212C2.31454 8.42464 2.20031 8.2602 2.12173 8.07839C2.04315 7.89659 2.00179 7.70104 2.00006 7.50317C1.99833 7.3053 2.03626 7.10906 2.11166 6.92592C2.18705 6.74278 2.29838 6.57639 2.43917 6.43647C2.57995 6.29655 2.74736 6.1859 2.93163 6.11097C3.1159 6.03604 3.31335 5.99834 3.51244 6.00006C3.71153 6.00178 3.90828 6.04289 4.09121 6.12099C4.27415 6.19909 4.43959 6.31261 4.57791 6.45495L12 13.8315Z' fill='black'/%3E%3C/svg%3E%0A");
		mask-repeat: no-repeat;

		transition: all ease 250ms;
	}

	&.active {
		&:before {
			margin-top: 0;
			rotate: 180deg;
		}
	}
}

.agreement {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.checkBox {
		display: flex;
		position: relative;
		width: 100%;

		input {
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			visibility: hidden;
		}

		input:checked + span:before {
			background-color: var(--cl-dark-blue);
		}

		span {
			padding-left: 35px;
			margin-bottom: 0;
			color: #757575;
			cursor: pointer;

			&,
			a {
				font-size: 14px;
				font-weight: 600;
				line-height: 22px;
			}

			a {
				display: inline-block;
				color: var(--cl-black);
				text-decoration: underline;
				transition: 0.3s ease;

				&:hover {
					color: var(--cl-blue);
				}
			}

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: ac(24px, 20px);
				height: ac(24px, 20px);
				border-radius: 4px;
				border: 1px solid var(--cl-dark-blue);
				transition: 0.3s ease;
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' fill='none'%3E%3Cpath fill='%23fff' d='M.541 7.875a1.8 1.8 0 0 1-.4-.585 1.763 1.763 0 0 1 .4-1.964 1.89 1.89 0 0 1 2.614 0l2.383 2.325L12.844.528A1.873 1.873 0 0 1 14.151 0c.49 0 .96.19 1.308.528.346.338.541.796.541 1.274s-.195.936-.541 1.274l-8.612 8.395a1.85 1.85 0 0 1-.6.392 1.887 1.887 0 0 1-2.015-.392L.542 7.875Z'/%3E%3C/svg%3E")
					center / 14px 10px no-repeat;
			}
		}
	}
}

.profile {
	&__input-box {
		p,
		& + p {
			font-size: 14px;
			line-height: 15px;
			font-weight: 500;
			color: var(--cl-dark-blue);
		}

		& + p {
			margin-top: 16px;
		}

		&:hover {
			.profile__btn {
				background-color: var(--cl-dark-blue);
				color: #fff;

				.dashboard__upload {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='M19.813 13.875v4.375a1.562 1.562 0 0 1-1.563 1.562H5.75a1.563 1.563 0 0 1-1.563-1.562v-4.375a.937.937 0 1 1 1.875 0v4.062h11.875v-4.062a.937.937 0 1 1 1.875 0ZM9.537 8.913l1.524-1.523v6.485a.938.938 0 0 0 1.876 0V7.39l1.524 1.525a.94.94 0 1 0 1.328-1.328l-3.125-3.125a.937.937 0 0 0-1.328 0L8.212 7.587A.94.94 0 0 0 9.54 8.915l-.002-.002Z'/%3E%3C/svg%3E");
				}
			}
		}
	}
	&__label {
		font-size: 16px;
		line-height: 18px;
		font-weight: 600;
		color: #757575;

		&.max-line-length {
			max-width: 300px;
			@mixin max-line-length-one;
		}
	}
	&__btn {
		padding: 11px ac(20px, 11px);
		border: 1px solid #757575;
		font-size: 16px;
		line-height: 18px;
		font-weight: 600;
		border-radius: 6px;
		transition: 0.3s ease;
	}
}

.counter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 6px;
	border: 1px solid var(--cl-white);
	height: 44px;
	max-height: 44px;
	width: 120px;
	overflow: hidden;
	background: var(--cl-white);
	position: relative;
	padding-left: 1px;
	padding-right: 1px;

	/* Chrome, Safari, Edge, Opera */
	input[type='number']::-webkit-outer-spin-button,
	input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		/*-moz-appearance: textfield;*/
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	input[type='number'] {
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	input[type='number']::-webkit-inner-spin-button {
		opacity: 0;
		pointer-events: none;
		display: none;
	}

	.counter-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		color: var(--cl-white);
		line-height: 1;
		transition: color 0.25s ease, background-color 0.25s ease;
		cursor: pointer;
		height: 42px;
		width: 42px;
		border-radius: 6px;
		border: 1px solid var(--cl-white);
		background: var(--cl-white);

		i {
			background: transparent !important;
			--size: 20px !important;
			--color: var(--cl-dark-blue) !important;
		}

		&:hover {
			background-color: var(--cl-blue);
			color: var(--cl-black) !important;

			i {
				--color: var(--cl-white) !important;
			}
		}
	}

	input {
		padding: 5px 0;
		font-family: var(--font-main);
		font-weight: 600;
		font-size: 16px;
		line-height: 120%;
		@mixin transparent-bg-input var(--cl-black);
		width: 40px;
		height: 40px;
		text-align: center;
		color: var(--cl-black);
	}

	/*&.counter-small {
    height: 32px;
    border-radius: 6px;

    .counter-btn {
      font-size: 32px;
    }

    input {
      width: 32px;
      height: 32px;
    }
  }*/

	[role='status'] {
		svg {
			fill: var(--cl-dark-blue);
			color: color-mod(var(--cl-dark-blue) a(40%));
		}
	}

	&.disabled {
		opacity: 0.8;
		cursor: not-allowed;
	}

	/*&.counter-input-num {
		border-radius: 6px;
		border: 1px solid var(--cl-lilac-450);
		height: 44px;
		max-height: 44px;
		width: 74px;
		background: var(--cl-lilac-50);
		padding-left: 10px;

		input {
			padding: 0;
			width: 32px;
			@mixin transparent-bg-input var(--cl-primary);
			background: transparent;
			text-align: left;
			color: var(--cl-primary);
			letter-spacing: -0.04em;
			font-size: 17px;
		}

		.counter-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 7px;
			color: var(--cl-lilac-500);
			line-height: 1;
			height: 22px;
			width: 22px;
			border-radius: 0;
			border: none;
			background: transparent;
			position: absolute;
			right: 8px;

			&.counter-btn-minus {
				bottom: 0;
			}

			&.counter-btn-plus {
				top: 0;

				i {
					transform: scaleY(-1);
				}
			}

			&:hover {
				background-color: transparent;
				color: var(--cl-lilac-950);
				filter: drop-shadow(0 0 1px var(--cl-lilac-500));
			}
		}
	}*/
}

.input-num {

	width: 80px;
	/* Chrome, Safari, Edge, Opera */
	input[type='number']::-webkit-outer-spin-button,
	input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		/*-moz-appearance: textfield;*/
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	input[type='number'] {
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	input[type='number']::-webkit-inner-spin-button {
		opacity: 0;
		pointer-events: none;
		display: none;
	}

	input {
		border: none !important;
		background: transparent !important;
		font-weight: 700 !important;
		padding-left: 0 !important;
		padding-bottom: 5px;
		width: auto;
		text-align: center;
		padding-right: 0 !important;
		margin-bottom: -5px !important;
		border-bottom: 2px solid var(--cl-dark-blue) !important;
		border-radius: 0;
	}
}
