.newsletter {
	margin-top: ac(86px, 40px);
	background-color: var(--cl-light-gray);

	&--job-search {
		border-top: 1px solid #e6e6e6;
		z-index: 1;

		.newsletter__decor {
			width: ac(895px, 400px);
			height: ac(824px, 400px);
			transform: translate(
				calc(-50% - ac(400px, 350px)),
				calc(-50% - 20px)
			);
		}
	}

	&--insights {
		border-top: 1px solid #e6e6e6;
		z-index: 1;

		.newsletter__wrapper {
			padding: ac(86px, 40px) 0;
		}

		.newsletter__decor {
			width: ac(895px, 400px);
			height: ac(824px, 400px);
			transform: translate(calc(-50% - ac(432px, 350px)), -50%);
		}
	}

	&__wrapper {
		gap: ac(56px, 20px);
		padding: ac(107px, 40px) 0;
	}

	&__title {
		color: var(--cl-dark-blue);
		font-family: var(--font-title);

		font-size: ac(48px, 36px);
		line-height: ac(56px, 40px);
		font-weight: 800;
	}

	&__subtitle {
		margin-top: ac(23px, 15px);
		font-size: ac(18px, 16px);
		line-height: ac(28px, 22px);
		color: var(--cl-dark-gray);
	}

	&__form {
		margin-top: ac(30px, 15px);

		input {
			background-color: var(--cl-white);
		}
	}

	&__img {
		border: 1px solid #e6e6e6;
	}

	&__agree {
		flex: 1 0 200px;
		font-size: 14px;
		line-height: 22px;
		color: var(--cl-dark-gray);

		a {
			font-size: 14px;
			line-height: 22px;
			color: var(--cl-dark-blue);
			text-decoration: underline;
			transition: 0.3s ease;

			&:hover {
				opacity: 0.7;
				text-decoration: none;
			}
		}
	}

	@mixin md {
		&__col {
			padding-top: 27px;
			max-width: ac(512px, 400px);
		}

		&__title {
			letter-spacing: -0.4px;
		}

		&__subtitle {
			letter-spacing: 0.2px;
		}
		&__agree {
			letter-spacing: 0.1px;
		}

		&--insights {
			.newsletter__col {
				padding-top: ac(82px, 40px);
			}
		}
	}

	@mixin max-md {
		&__img {
			max-width: 400px;
			margin: 0 auto;
		}

		&--insights,
		&--job-search {
			.newsletter__decor {
				width: 300px;
				height: 300px;
				top: -30px;
				left: -50px;
				transform: translate(0, 0);
			}
		}
	}
}
