.banner {
	margin-top: ac(86px, 40px);

	&__wrapper {
		padding: ac(120px, 40px, 320) ac(278px, 15px, 768);
		border: 1px solid #e6e6e6;
	}

	&__title {
		margin-bottom: ac(37px, 20px);
		font-size: ac(64px, 32px);
		line-height: ac(72px, 40px);
		font-weight: 800;
		font-family: var(--font-title);
	}

	&__descr {
		margin-bottom: ac(40px, 28px);
		color: var(--cl-black);
		max-width: ac(592px, 400px);
		text-align: center;
		p {
			font-size: ac(24px, 20px);
		}
	}

	&__links {
		display: flex;
		align-items: center;
		column-gap: 12px;
		.btn {
			width: fit-content;
		}
	}

	&__mask {
		width: ac(2738px, 1000px, 320);
		height: ac(959px, 500px, 320);
		transform: translate(-52.5%, -52%);
	}

	&__decor {
		width: ac(598px, 250px);
		height: ac(550px, 250px);
		transform: translate(
			calc(ac(630px, 200px) - 50%),
			calc(-50% - ac(225px, 100px))
		);
	}

	&:last-child {
		margin-bottom: ac(86px, 40px);
	}

	&-about {
		.banner {
			&__title {
				margin-bottom: ac(24px, 20px);
			}
		}
	}
}
