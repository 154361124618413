.slider-btn {
	&.swiper-button-lock {
		display: none !important;
	}

	&.swiper-button-disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	cursor: pointer;

	padding: 13px ac(19px, 10px);
	border: 1px solid var(--cl-dark-blue);
	border-radius: 6px;

	color: var(--cl-white);
	background: var(--cl-dark-blue);

	font-size: 16px;
	font-weight: 600;
	line-height: 1;

	transition: all ease 400ms;

	&:hover {
		border: 1px solid var(--cl-blue);
		color: var(--cl-dark-blue);
		background: var(--cl-white);
	}

	&.sm {
		padding: 10px ac(20px, 16px) 10px 20px;

		.btn__icon {
			width: 20px;
		}
	}

	&.transp {
		border-color: var(--cl-dark-blue);
		background: transparent;
		color: var(--cl-dark-blue);

		&:hover {
			background: var(--cl-dark-blue);
			color: var(--cl-white);
		}
	}

	&.transp-blue {
		border-color: var(--cl-blue);
		background: transparent;
		color: var(--cl-white);

		&:hover {
			background: var(--cl-blue);
		}
	}
	&.light {
		border-color: var(--cl-blue);
		background: var(--cl-white);
		color: var(--cl-dark-blue);

		&:hover {
			border-color: var(--cl-dark-blue);
			background: var(--cl-dark-blue);
			color: var(--cl-white);
		}
	}

	&.transparent {
		border-color: transparent;
		background: transparent;
		color: var(--cl-dark-blue);

		&:hover {
			color: var(--cl-blue);
		}
	}
	&.transparent-white {
		border-color: transparent;
		background: transparent;
		color: var(--cl-white);

		&:hover {
			color: var(--cl-blue);
		}
	}
}

.btn__icon {
	width: ac(22px, 18px);
}

.btn-save {
	.btn__icon {
		width: 19px !important;
		transition: all .3s ease;

		&.icon-star-bg {
			transform: scale(.9);
		}
	}
}

.btn-icon-only {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 44px;
	height: 44px;

	border: 1px solid var(--cl-dark-blue);
	border-radius: 6px;

	color: #4291cc;
	background: var(--cl-white);

	transition: all ease 400ms;

	&:hover {
		color: var(--cl-white);
		background: var(--cl-blue);
		border-color: var(--cl-blue);
	}

	i {
		width: 16px;
	}

	&.swiper-button-lock {
		display: none;
	}
}

.btn-show-hide {
	&__show {
		display: flex;
	}

	&__hide {
		display: none;
	}

	&:not(.is-visible) {
		opacity: 0;
		display: none;
	}

	&:not(.active) {
		.btn-show-hide__icon {
			i.icon-plus-second {
				opacity: 1;
			}
		}
	}

	&.active {
		.btn-show-hide__show {
			display: none;
		}

		.btn-show-hide__hide {
			display: flex;
		}

		.btn-show-hide__count {
			display: none;
		}
	}
}
