.plan-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-row-gap: 12px;
  margin-bottom: 1px;

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(20px, 16px);
    border-radius: ac(12px, 8px);
    border: 1px solid var(--cl-blue);
    padding: 20px ac(20px, 16px) 20px ac(28px, 16px);

    @media (max-width: 590px) {
      flex-wrap: wrap;
    }
  }

  &__item-icon {
    --size: ac(39px, 32px);
    width: var(--size);
    min-width: var(--size);
    height: var(--size);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__item-title {
    font-size: ac(28px, 24px);
    font-weight: 600;
    color: var(--cl-white);

    @media (max-width: 590px) {
      max-width: calc(100% - 50px);
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: 8px;
    margin-left: auto;

    @media (max-width: 590px) {
      width: 100%;
    }
  }

  &__item-price-info {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 8px;

    @media (max-width: 590px) {
      margin-left: 0;
      justify-content: center;
    }
  }

  &__item-price {
    font-size: ac(36px, 28px);
    font-weight: 400;
    line-height: 1.3;
    color: var(--cl-white);
  }

  &__item-info {
    font-size: ac(16px, 14px);
    font-weight: 400;
    line-height: 1.2;
    color: var(--cl-white);
  }

  &__item-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 273px;
    grid-column-gap: 12px;

    .btn {
      border-color: var(--cl-white);

      &.light {
        min-width: 120px;

        svg {
          width: 18px;
          height: 18px;
          min-width: 18px;
        }

        &:hover {
          background-color: var(--cl-blue);
        }
      }
    }

    @media (max-width: 590px) {
      justify-content: center;
    }
  }

  &__item-btn-counter {
    .counter {
      display: none;
    }

    &.active {
      .btn {
        display: none;
      }

      .counter {
        display: flex;
      }
    }
  }
}

.plan-section {
  padding-top: ac(104px, 76px);
  background: var(--cl-dark-blue);
  padding-bottom: ac(86px, 32px);
  color: var(--cl-white);

  &__top {
    background: var(--cl-blue);
    width: 100%;
    padding-top: ac(18px, 16px);
    padding-bottom: ac(18px, 16px);

    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }
  }

  &__top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 20px;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 16px;
    }
  }

  &__top-info {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 8px;

    span {
      font-size: ac(24px, 18px);
      font-weight: 500;
      line-height: 1.48;
      letter-spacing: 0.02em;
      color: var(--cl-white);
    }
    
    svg {
      --size: ac(40px, 32px);
      width: var(--size);
      height: var(--size);

      @media (max-width: 490px) {
        display: none;
      }
    }
  }

  &__top-buttons {

    .btn {
      white-space: nowrap;

      svg {
        --size: 20px;
        width: var(--size);
        height: var(--size);
        display: flex;
        justify-content: center;
        align-items: center;

        path {
          transition: .3s;
        }
      }

      &.light {
        &:hover {
          border-color: var(--cl-white);
          background-color: var(--cl-blue);

          svg {
            path {
              fill: var(--cl-white)
            }
          }
        }

      }
    }
  }

  &__title {
    font-family: var(--font-title);
    color: var(--cl-white);

    &:not(:last-child) {
      margin-bottom: ac(56px, 24px);
    }
  }

  &__list {
    grid-row-gap: ac(20px, 12px);

    .plan-list {
      &__item {
        padding-top: ac(28px, 24px);
        padding-left: ac(40px, 16px);
        padding-right: ac(40px, 16px);
        padding-bottom: ac(40px, 16px);
        border: 1px solid #469EDE;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &:nth-child(1) {
          background: rgba(255, 255, 255, 0.03);
        }

        &:nth-child(2) {
          background: rgba(255, 255, 255, 0.1);
        }

        &:nth-child(3) {
          background: rgba(255, 255, 255, 0.2);
        }

        &:nth-child(4) {
          background: rgba(255, 255, 255, 0.25);
        }

        &:nth-child(5) {
          background: rgba(255, 255, 255, 0.3);
        }

        &:nth-child(6) {
          background: rgba(255, 255, 255, 0.35);
        }
      }

      &__item-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: ac(24px, 18px);
        border-bottom: 1px solid var(--cl-blue);
        grid-column-gap: ac(24px, 14px);

        &:not(:last-child) {
          margin-bottom: ac(24px, 18px);
        }

        @media (max-width: 640px) {
          flex-wrap: wrap;
          grid-row-gap: 16px;
        }
      }
      
      &__item-icon {
        --size: ac(60px, 22px);
        width: var(--size);
        min-width: var(--size);
        height: var(--size);
      }

      &__item-title {
        font-size: ac(44px, 20px);
        line-height: 1.32;

        @media (max-width: 640px) {
          max-width: calc(100% - 40px);
        }
      }

      &__item-price-info {
        margin-left: auto;
        grid-column-gap: ac(12px, 8px);

        @media (max-width: 640px) {
          margin-left: 0;
          width: 100%;
          justify-content: flex-start;
        }
      }

      &__item-price {
        font-size: ac(56px, 28px);
        font-weight: 400;
        line-height: 1.32;
        color: var(--cl-white);
      }

      &__item-info {
        font-size: ac(20px, 15px);
        font-weight: 500;
        line-height: 1.2;
        color: var(--cl-white);
        white-space: nowrap;
      }

      &__item-bottom {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 640px) {
          flex-direction: column-reverse;
          grid-row-gap: 20px;
        }
      }

      &__item-content {
        width: 48.99%;
        margin-left: 0;

        @media (max-width: 640px) {
          width: 100%;
        }
      }

      &__item-text {
        font-size: ac(24px, 18px);
        font-weight: 400;
        line-height: 1.48;

        p {
          font-size: ac(24px, 18px);
          font-weight: 400;
          line-height: 1.48;
        }

        &:not(:last-child) {
          margin-bottom: ac(24px, 18px);
        }
      }

      &__item-buttons {
        min-width: 160px;
        justify-content: flex-start;

        .btn {
          min-width: 160px;
        }

        .counter {
          min-width: 160px;
        }
      }

      &__item-list {
        font-size: ac(24px, 18px);
        font-weight: 600;
        line-height: 1.44;
        width: 43.60%;

        li {
          font-size: ac(24px, 18px);
          font-weight: 600;
          line-height: 1.44;
          list-style: none;
          position: relative;
          padding-left: ac(32px, 26px);

          &:before {
            mask-image: url('../../public/images/check-list.svg');
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
            --size: ac(19px, 17px);
            width: var(--size);
            height: var(--size);
            background: #E6E6E6;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            content: "";
          }

          &:not(:last-child) {
            margin-bottom: ac(16px, 12px);
          }
        }

        @media (max-width: 1300px) {
          width: 46%;
        }

        @media (max-width: 640px) {
          width: 100%;
        }
      }
    }
  }
}