.cart-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  grid-row-gap: ac(28px, 16px);

  p {
    a {
      font: inherit;
      color: var(--cl-dark-blue);
      transition: .3s;
      text-decoration: underline;
      text-decoration-color: var(--cl-dark-blue);

      &:hover {
        color: var(--cl-blue);
        text-decoration-color: transparent;
      }
    }
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: ac(52px, 32px);
    position: relative;

    &:not(:last-child) {
      padding-bottom: ac(27px, 16px);
      border-bottom: 1px solid #E6E6E6;
    }
    
    @media (max-width: 590px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 12px;
    }
  }
  
  &__item-close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    --size: ac(32px, 28px);
    width: var(--size);
    height: var(--size);
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;

    svg {
      path {
        transition: .3s;
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--cl-dark-blue);
        }
      }
    }
  }

  &__item-title {
    max-width: calc(100% - ac(200px, 150px));
    font-size: ac(28px, 24px);
    font-weight: 500;
    line-height: 1.32;
    color: var(--cl-black);

    @media (max-width: 590px) {
      max-width: 100%;
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(20px, 12px);
    margin-left: auto;

    @media (max-width: 590px) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__item-price-info {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(8px, 6px);

    @media (max-width: 590px) {
      margin-left: 0;
    }
  }

  &__item-unit {
    font-size: ac(22px, 20px);
    font-weight: 500;
    line-height: 1.2;
    color: var(--cl-dark-blue);
    margin-right: ac(-4px, -2px);
  }

  &__item-price {
    font-size: ac(28px, 26px);
    font-weight: 500;
    line-height: 1.2;
    color: var(--cl-black);
  }

  &__item-slash {
    font-size: ac(15px, 14px);
    font-weight: 900;
    line-height: 1.2;
    color: #757575;
  }

  &__item-info {
    font-size: ac(16px, 14px);
    font-weight: 400;
    line-height: 1.2;
    color: #41484F;
  }

  &__item-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 150px;
    grid-column-gap: 12px;

    .btn {
      border-color: var(--cl-white);

      &.light {
        min-width: 120px;

        svg {
          width: 18px;
          height: 18px;
          min-width: 18px;
        }

        &:hover {
          background-color: var(--cl-blue);
        }
      }
    }

    .counter {
      background: #F5F5F5;
      border-color: #E6E6E6;


      .counter-btn {
        border-color: #F5F5F5;
        &:not(:hover) {
          background: #F5F5F5;
        }
      }

      input {
        background: #F5F5F5;
        @mixin transparent-bg-input var(--cl-black);
      }
    }

    @media (max-width: 590px) {
      justify-content: flex-start;
    }
  }

  &__item-btn-counter {
    .counter {
      display: none;
    }

    &.active {
      .btn {
        display: none;
      }

      .counter {
        display: flex;
      }
    }
  }
}

.cart-section {
  padding-top: ac(104px, 76px);
  background: #F5F5F5;
  padding-bottom: ac(86px, 32px);

  &__top {
    background: var(--cl-blue);
    width: 100%;
    padding-top: ac(18px, 16px);
    padding-bottom: ac(18px, 16px);

    &:not(:last-child) {
      margin-bottom: ac(64px, 32px);
    }
  }

  &__top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 20px;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      grid-row-gap: 16px;
    }
  }

  &__top-info {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 8px;

    span {
      font-size: ac(24px, 18px);
      font-weight: 500;
      line-height: 1.48;
      letter-spacing: 0.02em;
      color: var(--cl-white);
    }

    svg {
      --size: ac(40px, 32px);
      width: var(--size);
      height: var(--size);

      @media (max-width: 490px) {
        display: none;
      }
    }
  }

  &__top-buttons {

    .btn {
      white-space: nowrap;

      svg {
        --size: 20px;
        width: var(--size);
        height: var(--size);
        display: flex;
        justify-content: center;
        align-items: center;

        path {
          transition: .3s;
        }
      }

      &.light {
        &:hover {
          border-color: var(--cl-white);
          background-color: var(--cl-blue);

          svg {
            path {
              fill: var(--cl-white)
            }
          }
        }

      }
    }
  }

  &__title {
    font-family: var(--font-title);
    color: var(--cl-dark-blue);
    font-size: ac(64px, 38px);

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__content {
    width: 57.41%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: white;
    border-radius: ac(16px, 8px);
    border: 1px solid #E6E6E6;
    padding: ac(31px, 24px) ac(40px, 16px) ac(36px, 24px);
  }

  &__order {
    width: 40.38%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: white;
    border-radius: ac(16px, 8px);
    border: 1px solid #E6E6E6;
    padding: ac(28px, 24px) ac(40px, 16px) ac(32px, 24px);

    p {
      a {
        font: inherit;
        color: var(--cl-dark-blue);
        transition: .3s;
        text-decoration: underline;
        text-decoration-color: var(--cl-dark-blue);

        &:hover {
          color: var(--cl-blue);
          text-decoration-color: transparent;
        }
      }
    }
  }

  &__order-title {
    color: var(--cl-dark-blue);
    font-size: ac(32px, 24px);
    font-weight: 600;
    line-height: 1.32;
    &:not(:last-child) {
      margin-bottom: ac(20px, 18px);
    }
  }

  &__order-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    li {
      padding: 9px 0;
      border-bottom: 1px solid #E6E6E6;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-column-gap: 16px;
      min-height: ac(40px, 32px);

      span {
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: 1.32;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(52px, 24px);
    }
  }

  &__order-sum {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 16px;
    padding-bottom: ac(12px, 10px);
    border-bottom: 1px solid #E6E6E6;

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__order-sum-title {
    color: #757575;
    font-size: ac(20px, 17px);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.02em;
  }

  &__order-sum-price {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 6px;
    font-size: ac(40px, 32px);
    font-weight: 500;
    line-height: 1;

    p {
      font-size: ac(40px, 32px);
      font-weight: 500;
      line-height: 1;
    }

    span {
      color: var(--cl-dark-blue);
      font-size: ac(28px, 24px);
      line-height: 1;
    }
  }

  &__order-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    grid-row-gap: ac(16px, 14px);


    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__order-bottom-content {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: ac(8px, 6px);
  }

  
  &__order-bottom-icon {
    --size: ac(24px, 22px);
    width: var(--size);
    height: var(--size);
  }

  &__order-bottom-text {
    font-size: ac(18px, 16px);
    font-weight: 500;
    line-height: 1.44;
    letter-spacing: 0.02em;
    color: var(--cl-dark-blue);
    text-align: center;
  }

  &__order-bottom-buttons {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: ac(16px, 12px);
    grid-row-gap: 12px;

    .btn {
      min-height: ac(56px, 44px);

      @media (min-width: 552px) {
        white-space: nowrap;
        flex: 1;
      }

      @media (max-width: 551px) {
        width: 100%;
      }
    }

  }

  @media (max-width: 890px) {
    .cart-section {
      &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        grid-row-gap: 20px;
      }

      &__content {
        width: 100%;
      }

      &__order {
        width: 100%;
        max-width: 512px;
      }
    }
  }
}

.cart-popup {
  position: fixed;
  inset: 0;
  overflow: hidden;
  z-index: 9998;
  opacity: 0;
  pointer-events: none;
  transition: .3s;

  &__bg {
    background: var(--cl-black);
    opacity: 0;
    position: absolute;
    inset: 0;
  }

  &__block {
    position: absolute;
    top: 104px;
    right: 0;
    transform: translateX(100%);
    opacity: 0;
    transition: .4s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: ac(600px, 500px);
    padding-right: ac(86px, 20px);
    padding-left: ac(40px, 20px);
    background: var(--cl-white);
    /*border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #C5C3E3;*/
    box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 0px ac(24px, 16px);

    @media (max-width: 551px) {
      max-width: 100%;
      border-radius: 0 0 0 0;
    }
  }

  &__top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ac(18px, 16px);
    padding-bottom: ac(18px, 16px);
  }

  &__title {
    font-weight: 600;
    font-size: ac(36px, 24px);
    line-height: 132%;
    color: var(--cl-dark-blue);
    max-width: calc(100% - 50px);
  }

  &__close {
    --size: ac(44px, 40px);
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--cl-dark-blue);
    background: #F5F5F5;
    border-radius: 6px;
    transition: .3s;
    margin-left: auto;

    svg {
      --size: 15px;
      width: var(--size);
      height: var(--size);
    }

    &:hover {
      background-color: var(--cl-blue);
    }
  }

  &__content {
    padding-top: ac(32px, 20px);
    padding-bottom: ac(32px, 20px);
    background: #F5F5F5;
    width: calc(ac(86px, 20px) + ac(40px, 20px) + 100%);
    padding-right: ac(86px, 20px);
    margin-left: ac(-40px, -20px);
    padding-left: ac(40px, 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: 1px solid #E6E6E6;
  }

  &__scroll {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(ac(76px, 10px) + 100%);
    padding-right: ac(76px, 10px);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      top: ac(20px, 10px);
      bottom: ac(20px, 10px);

      .simplebar-scrollbar {
        background: var(--cl-gray);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .cart-list {
      grid-row-gap: ac(20px, 16px);

      &__item {
        &:not(:last-child) {
          padding-bottom: ac(19px, 16px);
          border-bottom: 1px solid #E6E6E6;
        }
      }

      &__item-title {
        max-width: calc(100% - ac(140px, 130px));
        font-size: ac(24px, 22px);

        @media (max-width: 590px) {
          max-width: 100%;
        }
      }

      &__item-price {
        font-size: ac(26px, 24px);
      }

      &__item-info {
        font-size: ac(15px, 14px);
        font-weight: 500;
        line-height: 1.2;
        color: #41484F;
      }

      &__item-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 120px;
        grid-column-gap: 12px;

        .btn {
          border-color: var(--cl-white);

          &.light {
            min-width: 120px;

            svg {
              width: 18px;
              height: 18px;
              min-width: 18px;
            }

            &:hover {
              background-color: var(--cl-blue);
            }
          }
        }

        .counter {
          background: var(--cl-white);
          border-color: var(--cl-white);


          .counter-btn {
            border-color: var(--cl-white);
            &:not(:hover) {
              background: var(--cl-white);
            }
          }

          input {
            background: var(--cl-white);
            @mixin transparent-bg-input var(--cl-black);
          }
        }
      }
    }
  }

  &__bottom {
    padding-top: ac(24px, 20px);
    padding-bottom: ac(40px, 24px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__order-sum {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 16px;
    padding-bottom: ac(12px, 10px);
    border-bottom: 1px solid #E6E6E6;

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__order-sum-title {
    color: #757575;
    font-size: ac(20px, 17px);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.02em;
  }

  &__order-sum-price {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 6px;
    font-size: ac(44px, 32px);
    font-weight: 500;
    line-height: 1;

    span {
      color: var(--cl-dark-blue);
      font-size: ac(28px, 24px);
      line-height: 1;
    }
  }

  &__order-buttons {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: ac(16px, 12px);
    grid-row-gap: 12px;

    .btn {
      min-height: ac(56px, 44px);

      @media (min-width: 552px) {
        white-space: nowrap;
        flex: 1;
      }

      @media (max-width: 551px) {
        width: 100%;
      }
    }
  }

  &__order-bottom-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    grid-column-gap: ac(8px, 5px);
    width: 100%;
    max-width: ac(235px, 200px);

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }


  &__order-bottom-icon {
    --size: ac(24px, 20px);
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    margin-top: 2px;
  }

  &__order-bottom-text {
    font-size: ac(18px, 14px);
    font-weight: 500;
    line-height: 1.44;
    letter-spacing: 0.02em;
    color: var(--cl-dark-blue);
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    transition: .0s;

    .cart-popup {
      &__block {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}