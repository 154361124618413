.our-timeline {
  padding-top: ac(86px, 48px);
  padding-bottom: ac(86px, 48px);
  overflow: hidden;
  &__decoration {
    position: absolute;
    right: 18px;
    top: 46%;
    transform: translateY(-50%);
    z-index: -1;
  }
  &__title {
    font-size: ac(48px, 32px);
    margin-bottom: ac(40px, 24px);
    color: var(--cl-black);
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: ac(56px, 32px);
    span {
      color: var(--cl-dark-blue);
    }
  }

  &__slider-container {
    height: 400px;
    transition: .2s;
    clip-path: inset(0 0 2px 0);
    padding-left: ac(110px, 0px);

    @media (max-width: 1200px) {
      padding-left: 0;
    }
  }

  &__slider-nav {
    padding-left: ac(110px, 0px);

    &.hidden {
      display: none;
    }

    @media (max-width: 1200px) {
      padding-left: 0;
    }
  }

  &__slider {
    /*overflow: hidden;*/
    overflow: hidden;
    max-height: 100%;
  }

  &__item {
    display: flex;
    position: relative;
    z-index: 3;
    align-items: flex-start;
    /* margin-bottom: ac(40px, 24px); */
    padding-bottom: ac(40px, 20px);
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 576px) {
      flex-direction: column;
    }
    &-date {
      display: flex;
      align-items: center;
      @media (max-width: 576px) {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid var(--cl-blue);
        width: fit-content;
        padding-right: 18px;
      }
      &__icon {

      }
      span {
        color: var(--cl-dark-blue);
        font-size: ac(48px, 32px);
        line-height: 1.2;
        margin-left: ac(16px, 12px);
        font-weight: 600;
      }
    }
    &-descr {
      color: var(--cl-dark-gray);
      font-size: ac(20px, 18px);
      padding-left: ac(16px, 14px);
      margin-left: ac(24px, 18px);
      border-left: 1px solid var(--cl-blue);
      max-width: ac(630px, 540px);
      max-height: ac(250px, 480px);
      scrollbar-width: thin;
      overflow: auto;
      @media (max-width: 576px) {
        padding-left: 0;
        margin-left: 0;
        border: none;
      }
      p {
        font-size: ac(20px, 18px);
        &:not(:last-child) {
          margin-bottom: ac(12px, 6px);
        }
      }
    }
  }
}