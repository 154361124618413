.insights {
	position: relative;
	overflow: hidden;
	max-width: 100vw;
	padding: ac(86px, 36px) 0 ac(86px, 48px);

	background: var(--cl-light-gray);
	.cont {
		position: relative;
		z-index: 1;
	}

	&--article {
		margin-top: ac(86px, 40px);
	}

	&__decor {
		position: absolute;
		width: ac(1092px, 344px, 375);
		height: ac(1005px, 317px, 375);
		bottom: -14px;
		left: 50%;
		transform: translate(calc(-50% + ac(70px, 0px)), 50%);

		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}

	&__top-wrap {
		display: flex;
		align-items: end;
		justify-content: space-between;
		gap: ac(24px, 19px);

		margin-bottom: ac(62px, 40px);

		@mixin media 551 {
			flex-direction: column;
			align-items: start;
		}
	}
}

.insights__slider-nav {
	display: flex;
	align-items: center;
	gap: ac(12px, 8px);
}

.insights__slider {
	margin-bottom: 48px;

	.swiper-slide {
		height: auto;
	}

	@mixin media 601 {
		margin-bottom: 0;
	}
}

.insight-card {
	position: relative;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	height: 100%;

	border-radius: 12px;
	border: 1px solid rgba(70, 158, 222, 0.5);

	background: var(--cl-light-gray);

	transition: background ease 250ms;

	&:hover {
		background: var(--cl-white);

		.insight-card__title {
			color: var(--cl-black);
		}
	}
}

.insight-card__hashtag-list {
	position: absolute;
	top: 0;
	left: 29px;

	display: flex;
	align-items: center;
	gap: 13px;
}

.insight-card_hashtag {
	padding: 6px;
	border-radius: 0 0 6px 6px;

	background: var(--cl-blue-gray);

	font-size: 14px;
	font-weight: 500;
}

.insight-card__img-wrap {
	min-height: ac(270px, 218px);

	border-bottom: 1px solid rgba(70, 158, 222, 0.5);
}

.insight-card__img {
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.insight-card__content-wrap {
	display: flex;
	flex-direction: column;
	gap: 14px;

	height: 100%;

	padding: ac(20px, 19px) ac(28px, 24px) ac(30px, 12px);
}

.insight-card__date-wrap {
	display: flex;
	align-items: center;
	gap: 8px;

	font-size: ac(16px, 14px);
	font-weight: 500;
}

.insight-card__date-label {
	color: var(--cl-gray);
}

.insight-card__title {
	color: var(--cl-dark-blue);

	font-size: ac(26px, 20px);
	font-weight: 600;
	line-height: ac(34px, 26px);
	max-height: calc(4 * ac(34px, 26px));
	@mixin max-line-length 4;
	transition: color ease 250ms;
}

.insight-card__btn {
	width: fit-content;

	margin-top: auto;
	padding: 9px;

	.btn__icon {
		width: 15px;
	}

	@mixin media 551 {
		padding: 9px 17px;
	}
}

.insight-card__description {
	max-height: calc(4 * 24px);
	@mixin max-line-length 4;

	* {
		font-size: 16px;
		color: #41484f;
		line-height: 24px;
	}
}

.insights__more-btn {
	width: fit-content;

	margin: 0 auto;
	padding: 14px 20px;

	@mixin media 601 {
		display: none;
	}
}
