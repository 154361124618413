.why {
  padding-top: ac(86px, 48px);
  padding-bottom: ac(86px, 48px);
  background: #C2DDF5;
  &-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 980px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-image {
    max-width: ac(728px, 520px, 980);
    margin-right: ac(56px, 18px, 980);
    position: relative;
    img {
      width: 100%;
      height: auto;
      border-radius: 20px;
      position: relative;
      z-index: 2;
    }
    &__decoration {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: ac(595px, 395px, 980);
      width: 100%;
      z-index: 0;
      top: ac(-31px, -24px);
      @media (max-width: 980px) {
        max-width: ac(590px, 240px, 320, 980);
        top: ac(-24px, -18px, 320, 980);
      }
    }
    @media (max-width: 980px) {
      margin-right: 0;
      max-width: 730px;
      margin-bottom: ac(32px, 20px, 420, 980);
    }
  }

  &-text {
    max-width: ac(485px, 340px, 980);
    @media (max-width: 980px) {
      max-width: 730px;
    }
  }
  &__title {
    font-size: ac(48px, 30px);
    color: var(--cl-black);
    font-weight: 600;
    margin-bottom: ac(24px, 18px);
    line-height: 1.2;
  }
  &__descr {
    font-size: ac(18px, 16px);
    line-height: 1.4;
    color: var(--cl-dark-gray);
    margin-bottom: ac(32px, 20px);
    max-height: 420px;
    scrollbar-width: thin;
    overflow: auto;
    p {
      &:not(:last-child) {
        margin-bottom: ac(12px, 6px);
      }
    }
  }
  &__btn {
    width: fit-content;
  }
}