.action-cards {
	position: relative;
	z-index: 1;
	overflow: hidden;

	margin-top: -43px;
	padding-top: ac(242px, 79px);
	padding-bottom: 22px;

	background: var(--cl-light-gray);

	@mixin media 1280 {
		margin-top: 0;
	}

	.cont {
		position: relative;
		z-index: 1;
	}

	@mixin media 1281 {
		padding-bottom: 32px;
	}
}

.action-cards__cloud {
	position: absolute;
	top: 0;
	left: 50%;
	translate: -50%;
	width: auto;
	object-fit: unset;
	min-width: 110%;

	@mixin media 551 {
		display: none;
	}

	@mixin min-media 1441 {
		min-width: unset;
		max-width: 1440px;
	}

	&.mob {
		display: none;

		min-width: 100%;

		@mixin media 551 {
			display: block;
		}
	}
}

.action-cards__list {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: ac(40px, 38px) ac(28px, 16px);

	@mixin media 769 {
		grid-template-columns: minmax(0, 1fr);
		grid-auto-rows: 1fr;
	}
}

.action-card {
	display: flex;
	flex-direction: column;

	padding: ac(28px, 20px) ac(30px, 24px) 0;
	border: 1px solid var(--cl-blue);
	border-radius: 12px;

	box-shadow: 0px 0px 16px 0px #00000014;
	background: var(--cl-white);

	.btn {
		width: fit-content;

		margin-left: auto;
		margin-top: auto;

		translate: 0 42%;

		@mixin media 551 {
			padding: 10px 10px 10px 19px;

			translate: 0 50%;
		}
	}
}

.action-card__title {
	margin-bottom: ac(16px, 8px);

	color: var(--cl-dark-blue);

	font-family: var(--font-title);
	font-size: ac(40px, 26px);
	line-height: 1.1;
	font-weight: 800;

	@mixin media 551 {
		line-height: 1.69;
	}
}

.action-card__description {
	margin-bottom: ac(17px, 6px);

	font-size: ac(18px, 16px);

	@mixin media 551 {
		line-height: 1.5;
	}
}
